// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.em4aZTMQ3S5sLhzxi0ND {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.Eo17Wevkljia1lmiRs7P {
  color: #45348e;
  font-size: 24px;
  text-align: center;
  margin-bottom: 10px;
}

.LcxcBhHmt2wzyRCZCX82 {
  color: #45348e;
  text-align: center;
  font-family: "Mulish", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.01456rem;
  margin-top: 16px;
  margin-bottom: 16px;
}

.NiaWLejRYClWDuHgtPaD {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.dN2IPwqkh8aQHOyv69bY {
  background-color: #f4f2fe;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
`, "",{"version":3,"sources":["webpack://./src/pages/views/onboardingAccountActivation/onboardingAccountActivation.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,cAAc;EACd,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,yCAAyC;AAC3C;;AAEA;EACE,cAAc;EACd,eAAe;EACf,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,iCAAiC;EACjC,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,0BAA0B;EAC1B,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,yBAAyB;EACzB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;AACf","sourcesContent":[".onboardingContainer {\n  max-width: 400px;\n  margin: 0 auto;\n  padding: 20px;\n  background-color: #fff;\n  border-radius: 8px;\n  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);\n}\n\n.mainTitle {\n  color: #45348e;\n  font-size: 24px;\n  text-align: center;\n  margin-bottom: 10px;\n}\n\n.subtitle {\n  color: #45348e;\n  text-align: center;\n  font-family: \"Mulish\", sans-serif;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n  letter-spacing: 0.01456rem;\n  margin-top: 16px;\n  margin-bottom: 16px;\n}\n\n.onboardingForm {\n  display: flex;\n  flex-direction: column;\n  gap: 15px;\n}\n\n.fullScreenContainer {\n  background-color: #f4f2fe;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100vh;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"onboardingContainer": `em4aZTMQ3S5sLhzxi0ND`,
	"mainTitle": `Eo17Wevkljia1lmiRs7P`,
	"subtitle": `LcxcBhHmt2wzyRCZCX82`,
	"onboardingForm": `NiaWLejRYClWDuHgtPaD`,
	"fullScreenContainer": `dN2IPwqkh8aQHOyv69bY`
};
export default ___CSS_LOADER_EXPORT___;
