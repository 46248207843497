export const validationScheme = (
  e: any,
  values,
  errors,
  setErrors: Function
) => {
  const { name, value } = e.target;

  const letterNumberRegex = /^(?=.*[a-zA-Z])(?=.*\d).*$/g;

  switch (name) {
    case "password":
      if (value.length === 0) {
        setErrors({ ...errors, password: "Campo requerido" });
        break;
      } else if (value.length < 8) {
        setErrors({
          ...errors,
          password: "La contraseña debe tener al menos 8 caracteres",
        });
        break;
        // debe validar si tiene al menos un número y una letra
      } else if (!letterNumberRegex.test(value)) {
        setErrors({
          ...errors,
          password: "La contraseña debe tener al menos una letra y un número",
        });
        break;
      } else if (value !== values.confirmPassword) {
        setErrors({
          password: "",
          confirmPassword: "Las contraseñas no coinciden",
        });
        break;
      } else {
        setErrors({ ...errors, password: "" });
        if (value === values.confirmPassword) {
          setErrors({ ...errors, confirmPassword: "" });
        }
        break;
      }
    case "confirmPassword":
      if (value.length === 0) {
        setErrors({ ...errors, confirmPassword: "Campo requerido" });
        break;
      } else if (value !== values.password) {
        setErrors({
          ...errors,
          confirmPassword: "Las contraseñas no coinciden",
        });
        break;
      } else {
        validationScheme(
          {
            target: {
              name: "password",
              value: values.password,
            },
          },
          { ...values, confirmPassword: value },
          errors,
          setErrors
        );
        setErrors({ ...errors, confirmPassword: "" });
        break;
      }

    default:
      break;
  }
};
