import { getSearchClientReniec } from "@Services/admin/getSearchClientReniec";
import { capitalizeFirstLetters } from "@Utilities/capitalizeFirstLetters";

const adaptInfoPerson = async (DNINumber, setValues) => {
  // const response = await getSearchClientReniec(DNINumber);
  // try {
  //     setValues((prev) => ({
  //         ...prev,
  //         firstName: capitalizeFirstLetters(response.data.nombres),
  //         lastName: capitalizeFirstLetters(`${response.data.apellidoPaterno} ${response.data.apellidoMaterno}`),
  //         birthDate: response.data.fechaDeNacimiento,
  //         selectedSex: response.data.sexo,
  //     }));
  // } catch (error) {
  //     setValues((prev) => ({
  //         ...prev,
  //     }));
  // }
};

export default adaptInfoPerson;
