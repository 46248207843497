import React, { useEffect, useState } from "react";
import style from "./LoaderScreen.module.css";
import { LoadingPulseIcon } from "@Models/icons";

const LoaderScreen = ({ visible }) => {
  const [dots, setDots] = useState("");

  useEffect(() => {
    let interval = setInterval(() => {
      setDots((dots) => (dots.length < 3 ? dots + "." : ""));
    }, 500);
    return () => clearInterval(interval);
  }, []);

  return (
    <div
      className={`${style.loadingContainer} ${
        visible ? style.visible : style.hidden
      }`}
    >
      <img src={LoadingPulseIcon} alt="" />
      <p className={style.loadingText}>Cargando{dots}</p>
    </div>
  );
};

export default LoaderScreen;
