// @ts-ignore
import { ModalAcceptCancel, Icon } from "@viuti/recursos";
import { WarningTriangleIcon } from "@Models/icons";
import style from "./ModalWarning.module.css";

const ModalWarningExport = ({ setOnClose }: any) => {
  const modalProps = {
    showButtons: {
      showButtonTwo: true,
    },
    children: <ModalWarning />,
    buttonTwo: {
      textButtonTwo: "Ok, entendido",
      actionButtonTwo: () => {
        setOnClose(false);
      },
    },
    noSizeLimit: true,
  };

  return <ModalAcceptCancel {...modalProps} />;
};

const ModalWarning = () => {
  return (
    <div className={style.container}>
      <Icon size={96} path={WarningTriangleIcon} />
      <h1 className={style.title}>Términos de uso de la información</h1>
      <p className={style.paragraph}>
        Toda la información que ingreses en el siguiente formulario servirá
        únicamente para efectos de la creación de tu cuenta. Prikly no
        comercializará tu información ni se la cederá o venderá a terceros
      </p>
    </div>
  );
};

export default ModalWarningExport;
