import { IToasterState } from "@Models/interfaces/toaster";
import { IFormValues } from "@Pages/views/onboardingAdmin/OnboardingAdmin.page";
import createAdminUser_service from "@Services/admin/createAdmin/CreateAdminUser.service";

export const createAdminUser = async (
  adminCredentials: IFormValues,
  handleToasterStateChange: (state: IToasterState) => void
): Promise<any> => {
  const adminDataAdapter = {
    email: adminCredentials.email,
    password: adminCredentials.password,
    firstName: adminCredentials.personalName,
    lastName: adminCredentials.personalSurname,
    phone: adminCredentials.mobilePhone,
    businessName: adminCredentials.businessName,
    website: adminCredentials.businessWebsite,
    country: 0,
    address: adminCredentials.businessDirection,
  };

  const response = await createAdminUser_service(adminDataAdapter);

  if (!response.isSuccess) {
    handleToasterStateChange({
      message: response.message,
      status: response.status,
      loading: false,
    });
  }

  return response;
};
