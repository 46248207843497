import trackEmail_service from "@Services/admin/trackEmail/TrackEmail.service";

export const trackEmailBrandingAdapter = async (
  searchParams: URLSearchParams
) => {
  const orgType = searchParams.get("org_type");
  const tk = searchParams.get("tk");

  if (!orgType || !tk) {
    return;
  }

  const payload = {
    origin: Number(orgType),
    hash: tk,
  };

  return await trackEmail_service(payload);
};
