import axios from "axios";
import { onboardingAdmin_GW } from "../const";

const createEverythingService = async (props) => {
  const localToken = localStorage.getItem("access_token");
  const url = `${onboardingAdmin_GW}/Onboarding/CreateEverything`;

  try {
    const response = await axios.post(url, props, {
      headers: {
        Authorization: `Bearer ${localToken}`,
      },
    });

    return {
      isSuccess: true,
      data: response.data.data,
    };
  } catch (error) {
    return {
      isSuccess: false,
      status: 500,
      message: "Error",
      data: null,
    };
  }
};

export default createEverythingService;
