export const ONBOARDING_BASENAME = "/onboarding";
const EMPLOYEE_BASENAME = "/onboarding-employee";
export const EMPLOYEE_MODULE_BASENAME = EMPLOYEE_BASENAME;

export const ROUTE_EMPLOYEES = {
  ROUTE_MAIN_VIEW: `${EMPLOYEE_MODULE_BASENAME}/:token?`,
  PERSONAL_INFORMATION: `${EMPLOYEE_MODULE_BASENAME}/informacion-personal`,
  PERSONAL_INFORMATION_TWO: `${EMPLOYEE_MODULE_BASENAME}/datos-personales`,
  PROFILE_PICTURE: `${EMPLOYEE_MODULE_BASENAME}/foto-perfil`,
  SERVICES: `${EMPLOYEE_MODULE_BASENAME}/servicios`,
  SCHENDULES: `${EMPLOYEE_MODULE_BASENAME}/horarios`,
  COMPLETED: `${EMPLOYEE_MODULE_BASENAME}/completado`,
};
