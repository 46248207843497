import { useEffect, useRef, useState } from "react";
import style from "./CustomSelect.module.css";

import { CircleAlertIcon, AltArrowRightIcon } from "@Models/icons";
import bandera_peru from "@Assets/svg/bandera_peru.svg";

interface IPhoneSelectInputProps {
  phoneValues: {
    phoneCode: number;
    phoneNumber: string;
  };
  setPhoneValues: Function;
  handleInputChange: Function;
  error: string;
  inputProps?: any;
}

const CustomSelect = ({
  setPhoneValues,
  phoneValues,
  handleInputChange,
  error,
  inputProps,
}: IPhoneSelectInputProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true); // Estado para validar el número de teléfono
  const selectRef = useRef(null);

  const handleOutsideClick = (event) => {
    if (selectRef.current && !selectRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const handleCodeChange = () => {
    // setPhoneValues({
    //   ...phoneValues,
    //   phoneCode: e.target.value,
    // });
    setIsOpen(false);
  };

  const countryCodes = [
    { value: 51, text: "+51", image: bandera_peru, imageAlt: "peru" },
  ];
  const selectedCountryCode = countryCodes.find(
    (option) => option.value === phoneValues.phoneCode
  );

  const toggleSelect = () => {
    setIsOpen(!isOpen);
  };

  const handlePhoneNumberBlur = () => {
    // Verifica si el número de teléfono está vacío
    if (!phoneValues.phoneNumber) {
      setIsPhoneNumberValid(false); // Marca como inválido si está vacío
    } else {
      setIsPhoneNumberValid(true); // Marca como válido si tiene valor
    }
  };

  useEffect(() => {
    localStorage.setItem("phoneCode", phoneValues.phoneCode.toString());
  }, [phoneValues.phoneCode]);

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  return (
    <>
      <div
        className={
          error || !isPhoneNumberValid // Agrega la validación al contenedor
            ? `${style.phoneContainer} ${style.errorInput}`
            : style.phoneContainer
        }
      >
        <button
          className={style.selectTrigger}
          onClick={toggleSelect}
          type="button"
          ref={selectRef}
        >
          <img
            src={selectedCountryCode.image}
            alt={selectedCountryCode.imageAlt}
            className={style.selectedOption}
          />
          <img
            src={AltArrowRightIcon}
            className={`${style.arrowIcon} ${
              isOpen ? style.activeArrowIcon : ""
            }`}
            alt={selectedCountryCode.imageAlt}
          />
          <span className={style.phoneCode}>{selectedCountryCode.text}</span>
        </button>
        <input
          className={`${style.phoneInput} ${
            !isPhoneNumberValid ? style.errorInput : ""
          }`}
          type="number"
          placeholder="Ingrese su número de teléfono"
          value={phoneValues.phoneNumber}
          onChange={(e) => {
            if (e.target.value.length <= 9) {
              handleInputChange(e, phoneValues, setPhoneValues);
            }
          }}
          onBlur={handlePhoneNumberBlur} // Agrega el evento onBlur
          name="phoneNumber"
          maxLength={9}
          autoComplete="tel"
          {...inputProps}
        />
      </div>
      {/* {!isPhoneNumberValid &&
        !error && ( // Muestra mensaje de error si el número es inválido
          <div className={style.inputErrorContainer}>
            <img src={CircleAlertIcon} alt="warn" className={style.warnYou} />
            <p className={style.inputErrorMessage}>Campo requerido.</p>
          </div>
        )} */}
      {error && (
        <div className={style.inputErrorContainer}>
          <img src={CircleAlertIcon} alt="warn" className={style.warnYou} />
          <p className={style.inputErrorMessage}>{error}</p>
        </div>
      )}
      {isOpen && (
        <div className={style.contenedorScroll}>
          <ul className={style.selectOptions}>
            {countryCodes.map((option) => (
              <li key={option.value}>
                <button value={option.value} onClick={handleCodeChange}>
                  <img src={option.image} alt={option.text} />
                  {option.text}
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};

export default CustomSelect;
