import { useEffect, useState } from "react";
import {
  TextInput,
  ButtonWithoutIcon,
  SelectInput,
  PasswordInput,
  IconWrapper,
  //@ts-ignore
} from "@viuti/recursos";
import CustomSelect from "@Pages/views/onboardingEmployee/PersonalInformationTwo/CustomSelect";
import style from "./AccountForm.module.css";
import ModalWarningExport from "./components/ModalWarning/ModalWarning";
import { WhatsAppIcon } from "@Models/icons";
import { IoClose } from "react-icons/io5";
import { trackEmailBrandingAdapter } from "@Adapters/admin/trackEmailBranding.adapter";

const AccountForm = ({
  setFormValues,
  formValues,
  isLoading,
  continueWithTheOnboarding,
}) => {
  const REGUEX_EMAIL = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
  const [onClose, setOnClose] = useState(false);
  const [country, setCountry] = useState("Perú");
  const REGEX_PASSWORD = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [repeatedPassword, setRepeatedPassword] = useState("");
  const [error, setError] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);
  const [textInputError, setTextInputError] = useState({
    errorName: false,
    errorLastName: false,
    errorBuisnessName: false,
    errorMobilePhone: false,
    // errorAddress: false,
  });
  const [consent, setConsent] = useState(false);

  const requiredFields = {
    personalName: formValues.personalName,
    personalSurname: formValues.personalSurname,
    mobilePhone: formValues.mobilePhone,
    businessName: formValues.businessName,
    country: country,
    email: formValues.email,
    password: formValues.password,
    confirmPassword: repeatedPassword,
  };
  const areRequiredFieldsFilled = Object.values(requiredFields).every(
    (value) => value !== ""
  );

  const [errorPhone, setErrorPhone] = useState("");
  useEffect(() => {
    const ACCEPTED = /^[1-9]\d{8}$/;
    if (!ACCEPTED.test(formValues.mobilePhone)) {
      setErrorPhone("Ingresa un número válido");
    } else {
      setErrorPhone("");
    }
  }, [formValues.mobilePhone]);

  const [isWhatsAppButtonVisible, setIsWhatsAppButtonVisible] = useState(true);
  const [showWhatsAppButtonBelow, setShowWhatsAppButtonBelow] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkIfMobile();
    window.addEventListener("resize", checkIfMobile);

    return () => window.removeEventListener("resize", checkIfMobile);
  }, []);

  const handleWhatsAppClick = () => {
    if (isMobile && !isExpanded) {
      setIsExpanded(true);
    } else {
      const message = encodeURIComponent(
        "¡Hola! Quiero registrarme en Prikly y necesito ayuda."
      );
      window.open(`https://wa.me/51905453587?text=${message}`, "_blank");
    }
  };

  const handleCloseFloatingButton = () => {
    setIsWhatsAppButtonVisible(false);
    setTimeout(() => {
      setShowWhatsAppButtonBelow(true);
    }, 300);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    trackEmailBrandingAdapter(searchParams);
  }, []);

  // Permitir letras, números, espacios y símbolos comunes en nombres de negocios
  const BUSINESS_NAME_REGEX = /^[a-zA-ZáéíóúÁÉÍÓÚñÑ0-9\s&.,'\-_#+@]+$/;

  return (
    <>
      {onClose && (
        <ModalWarningExport
          setOnClose={setOnClose}
          continueWithTheOnboarding={continueWithTheOnboarding}
        />
      )}
      <h1 className={style.mainContent__title}>¡Bienvenido a Prikly!</h1>
      <p className={style.mainContent__subtitle}>
        Solo necesitamos algunos datos, validamos tu correo y ¡listo!
      </p>
      <form
        className={style.mainContent__emailCard}
        onSubmit={(e) => {
          e.preventDefault();
          continueWithTheOnboarding();
        }}
      >
        <div className={style.personal_form__container}>
          <div className={style.container__row}>
            <TextInput
              label="Nombres"
              width="100%"
              flex="1"
              placeholder="Ingresa tus nombres"
              value={formValues.personalName}
              handleChange={(e) => {
                const PROHIBITED = /^[^0-9!@#$%^&*(),.?":{}|<>]+$/;
                if (e.target.value.length < 3) {
                  setTextInputError({
                    ...textInputError,
                    errorName: true,
                  });
                } else if (e.target.value.length >= 3) {
                  setTextInputError({
                    ...textInputError,
                    errorName: false,
                  });
                }
                if (e.target.value === "")
                  setFormValues({
                    ...formValues,
                    personalName: e.target.value,
                  });
                if (!PROHIBITED.test(e.target.value)) return;
                setFormValues({ ...formValues, personalName: e.target.value });
              }}
              required={true}
              id="onboardingAdmin_createNewUserText_personalName"
              error={"El nombre debe contener al menos 3 caracteres."}
              touched={textInputError.errorName}
            />
            <TextInput
              label="Apellidos"
              placeholder="Ingresa tus apellidos"
              value={formValues.personalSurname}
              width="100%"
              flex="1"
              handleChange={(e) => {
                const PROHIBITED = /^[^0-9!@#$%^&*(),.?":{}|<>]+$/;
                if (e.target.value.length < 3) {
                  setTextInputError({
                    ...textInputError,
                    errorLastName: true,
                  });
                } else if (e.target.value.length >= 3) {
                  setTextInputError({
                    ...textInputError,
                    errorLastName: false,
                  });
                }
                if (e.target.value === "")
                  setFormValues({
                    ...formValues,
                    personalSurname: e.target.value,
                  });
                if (!PROHIBITED.test(e.target.value)) return;
                setFormValues({
                  ...formValues,
                  personalSurname: e.target.value,
                });
              }}
              required={true}
              id="onboardingAdmin_createNewUserText_personalLastName"
              error={"El apellido debe contener al menos 3 caracteres."}
              touched={textInputError.errorLastName}
            />
          </div>
          <div className={style.container__row}>
            <div className={style.countrySelectContainer}>
              <div className={style.containerTextTelephoneNumber}>
                <p>Teléfono móvil</p>
                {<p className={style.inputRequired}>*</p>}
              </div>
              <CustomSelect
                phoneValues={{
                  phoneCode: 51,
                  phoneNumber: formValues.mobilePhone,
                }}
                setPhoneValues={() => {}}
                handleInputChange={(e) => {
                  const ACCEPTED = /^\d+$/;
                  if (e.target.value.length < 9) {
                    setTextInputError({
                      ...textInputError,
                      errorMobilePhone: true,
                    });
                  } else if (e.target.value.length >= 9) {
                    setTextInputError({
                      ...textInputError,
                      errorMobilePhone: false,
                    });
                  }
                  if (e.target.value === "")
                    setFormValues({
                      ...formValues,
                      mobilePhone: e.target.value,
                    });
                  if (!ACCEPTED.test(e.target.value)) return;
                  setFormValues({
                    ...formValues,
                    mobilePhone: e.target.value,
                  });
                }}
                error={formValues.mobilePhone !== "" && errorPhone}
                inputProps={{
                  placeholder: "Ingresa tu número de teléfono",
                  required: true,
                }}
              />
            </div>
            <TextInput
              label="Nombre del negocio"
              placeholder="Ingresa el nombre de tu negocio"
              width="100%"
              flex="1"
              value={formValues.businessName}
              handleChange={(e) => {
                const value = e.target.value;
                if (value.length < 3) {
                  setTextInputError({
                    ...textInputError,
                    errorBuisnessName: true,
                  });
                } else if (value.length >= 3) {
                  setTextInputError({
                    ...textInputError,
                    errorBuisnessName: false,
                  });
                }

                // Actualizar solo si está vacío o cumple con el patrón
                if (value === "" || BUSINESS_NAME_REGEX.test(value)) {
                  setFormValues({ ...formValues, businessName: value });
                }
              }}
              required={true}
              id="onboardingAdmin_createNewUserText_businessName"
              error={"El nombre debe contener al menos 3 caracteres."}
              touched={textInputError.errorBuisnessName}
            />
          </div>
          <div className={style.container__row}>
            <TextInput
              label="Sitio web"
              width="100%"
              flex="1"
              placeholder="Ingresa el sitio web de tu negocio"
              value={formValues.businessWebsite}
              handleChange={(e) =>
                setFormValues({
                  ...formValues,
                  businessWebsite: e.target.value,
                })
              }
              id="onboardingAdmin_createNewUserText_businessWebsite"
              // touched={formValues.businessWebsite !== ""}
            />
            <SelectInput
              label="País"
              width="100%"
              flex="1"
              placeholder="Selecciona tu país"
              items={["Perú"]}
              handleChange={(item) => setCountry(item.target.value)}
              value={country}
              required={true}
            />
          </div>
          <div className={style.container__row}>
            <TextInput
              label="Dirección del negocio"
              width="100%"
              flex="1"
              placeholder="Ingresa la dirección de tu negocio"
              value={formValues.businessDirection}
              handleChange={(e) => {
                setFormValues({
                  ...formValues,
                  businessDirection: e.target.value,
                });
              }}
              id="onboardingAdmin_createNewUserText_businessDirection"
            />
            <TextInput
              label="Correo electrónico"
              width="100%"
              flex="1"
              placeholder="Ingresa tu correo electrónico"
              value={formValues.email}
              handleChange={(e) => {
                setFormValues({ ...formValues, email: e.target.value });
                REGUEX_EMAIL.test(e.target.value)
                  ? setErrorEmail(false)
                  : setErrorEmail(true);
              }}
              error={errorEmail && "Ingresa un correo electrónico válido"}
              touched={true}
              required={true}
              inputProps={{
                type: "email",
                required: true,
                autoComplete: "new-email",
              }}
              id="onboardingAdmin_createNewUserText_email"
            />
          </div>
          <div className={style.container__row}>
            <PasswordInput
              visibility={{
                isVisible: isPasswordVisible,
                setIsVisible: setIsPasswordVisible,
                isToggleAvailable: true,
              }}
              label="Contraseña"
              width="100%"
              flex="1"
              inputProps={{
                placeholder: "Ingresa tu contraseña",
                value: formValues.password,
                name: "password",
                onChange: (e) => {
                  setFormValues({ ...formValues, password: e.target.value });
                  REGEX_PASSWORD.test(e.target.value)
                    ? setError(false)
                    : setError(true);
                },
                maxLength: 25,
                type: isPasswordVisible ? "text" : "password",
                required: true,
                autoComplete: "new-password",
              }}
              error={
                "La contraseña debe tener al menos 8 caracteres, una mayúscula, una minúscula y un número."
              }
              touched={error}
            />

            <PasswordInput
              visibility={{
                isVisible: isPasswordVisible,
                setIsVisible: setIsPasswordVisible,
                isToggleAvailable: true,
              }}
              label="Confirmar contraseña"
              width="100%"
              flex="1"
              inputProps={{
                placeholder: "Ingresa tu contraseña",
                value: repeatedPassword,
                name: "password",
                onChange: (e) => {
                  setRepeatedPassword(e.target.value);
                },
                maxLength: 25,
                type: isPasswordVisible ? "text" : "password",
                required: true,
                autoComplete: "new-password",
              }}
              error={"La contraseña no coincide"}
              touched={repeatedPassword !== formValues.password}
            />
          </div>

          <div className={style.checkboxContainer}>
            <input
              type="checkbox"
              id="consentCheckbox"
              checked={consent}
              onChange={(e) => setConsent(e.target.checked)}
              required
            />
            <label htmlFor="consentCheckbox" className={style.checkboxLabel}>
              He leído y acepto los
              <a
                className={style.termsLink}
                href="https://www.prikly.io/pol%C3%ADtica-de-privacidad"
                target="_blank"
                rel="noopener noreferrer"
              >
                términos y condiciones.
              </a>
            </label>
          </div>
        </div>
        <ButtonWithoutIcon
          width="100%"
          isPrimary={true}
          textBttn="Continuar"
          handleClick={() => {
            continueWithTheOnboarding();
          }}
          isDisabled={
            !areRequiredFieldsFilled ||
            error ||
            formValues.email === "" ||
            formValues.password === "" ||
            textInputError.errorName ||
            textInputError.errorLastName ||
            textInputError.errorMobilePhone ||
            textInputError.errorBuisnessName ||
            repeatedPassword !== formValues.password ||
            errorEmail === true ||
            isLoading ||
            !consent
          }
          isLoading={isLoading}
          buttonProps={{
            type: "button",
          }}
        />
      </form>
      <div className={style.signInContainer}>
        <p className={style.signIn}>
          ¿Ya tienes cuenta? ingresa haciendo <a href="/">clic aquí</a>
        </p>
        {showWhatsAppButtonBelow && (
          <button
            className={style.whatsappButton}
            onClick={handleWhatsAppClick}
          >
            <img src={WhatsAppIcon} alt="WhatsApp" />
            <span>¿Necesitas ayuda?</span>
          </button>
        )}
      </div>

      {isWhatsAppButtonVisible && (
        <div
          className={`${style.whatsappFloatingButtonContainer} ${
            isMobile ? style.mobile : ""
          } ${isExpanded ? style.expanded : ""}`}
        >
          <button
            className={style.whatsappFloatingButton}
            onClick={handleWhatsAppClick}
          >
            <img src={WhatsAppIcon} alt="WhatsApp" />
            {(!isMobile || isExpanded) && <span>¿Necesitas ayuda?</span>}
          </button>
          {(!isMobile || isExpanded) && (
            <button
              className={style.toggleWhatsAppButton}
              onClick={handleCloseFloatingButton}
            >
              <IconWrapper icon={IoClose} className={style.closeIcon} />
            </button>
          )}
        </div>
      )}
    </>
  );
};

export default AccountForm;
