import { validationScheme } from "./validationScheme";

export const handleInputChange = (
  e,
  setValues,
  touched,
  setTouched,
  errors,
  setErrors
) => {
  const { name, value } = e.target;

  setValues((prevForm) => ({
    ...prevForm,
    [name]: value,
  }));

  setTouched({ ...touched, [name]: true });
  localStorage.setItem(name, value);
  validationScheme(e, errors, setErrors);
};
