import createEverythingService from "@Services/admin/createAdmin/createEverything.service";

export interface IInitCreationAdminUserAdapter {
  token: string;
  bitGoogle: 0;
}

const createEverything = async (props: IInitCreationAdminUserAdapter) => {
  const resolve = await createEverythingService(props);
  return resolve;
};

export default createEverything;
