import React, { useContext, useState } from "react";
import {
  ButtonWithoutIcon,
  PasswordInput,
  SlideNotification,
  // @ts-ignore
} from "@viuti/recursos";
import styles from "./onboardingAccountActivation.module.css";
import { IToasterStore, ToasterContext } from "@Hooks/useToasterContext";
import { registerPasswordsAdapter } from "@Adapters/registerPasswords.adapter";
import CompletedAccountActivation from "./CompletedAccountActivation/CompletedAccountActivation";

const OnboardingAccountActivation = () => {
  const REGEX_PASSWORD = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
  const [formValues, setFormValues] = useState({
    password: "",
    confirmPassword: "",
  });
  const uuidOnboarding = localStorage.getItem("uuidOnboarding");
  const [errors, setErrors] = useState<{
    password?: string;
    confirmPassword?: string;
  }>({});
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] =
    useState(false);
  const [error, setError] = useState(false);
  const [touched, setTouched] = useState({
    password: false,
    confirmPassword: false,
  });
  const toasterContext = useContext(ToasterContext) as IToasterStore;
  const [isLoading, setIsLoading] = useState(false);
  const [completed, setCompleted] = useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    validateField(name, value);

    if (name === "password") {
      REGEX_PASSWORD.test(value) ? setError(false) : setError(true);
    }
    setTouched((prev) => ({ ...prev, [name]: true }));
  };

  const validateField = (name: string, value: string) => {
    let newErrors = { ...errors };

    if (name === "password") {
      if (!REGEX_PASSWORD.test(value)) {
        newErrors.password =
          "La contraseña debe tener al menos 8 caracteres, una mayúscula, una minúscula y un número.";
      } else {
        delete newErrors.password;
      }

      if (formValues.confirmPassword && value !== formValues.confirmPassword) {
        newErrors.confirmPassword = "Las contraseñas no coinciden";
      } else {
        delete newErrors.confirmPassword;
      }
    }

    if (name === "confirmPassword") {
      if (value !== formValues.password) {
        newErrors.confirmPassword = "Las contraseñas no coinciden";
      } else {
        delete newErrors.confirmPassword;
      }
    }

    setErrors(newErrors);
  };

  // const validateForm = () => {
  //   let newErrors: { password?: string; confirmPassword?: string } = {};
  //   if (!REGEX_PASSWORD.test(formValues.password)) {
  //     newErrors.password =
  //       "La contraseña debe tener al menos 8 caracteres, una mayúscula, una minúscula y un número.";
  //     setError(true);
  //   } else {
  //     setError(false);
  //   }
  //   if (formValues.password !== formValues.confirmPassword) {
  //     newErrors.confirmPassword = "Las contraseñas no coinciden";
  //   }
  //   setErrors(newErrors);
  //   return Object.keys(newErrors).length === 0;
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const response = await registerPasswordsAdapter({
      password: formValues.password,
      uuidOnboarding: uuidOnboarding,
    });
    setIsLoading(false);
    if (response.isSuccess) {
      setCompleted(true);
    } else {
      toasterContext.handleToasterStateChange({
        message:
          "Hubo un error al activar la cuenta, por favor intenta de nuevo",
        status: 400,
        loading: false,
      });
    }
  };
  if (completed) {
    return <CompletedAccountActivation />;
  }
  return (
    <div className={styles.fullScreenContainer}>
      <section className={styles.onboardingContainer}>
        <h1 className={styles.mainTitle}>¡Activa tu cuenta de Prikly!</h1>

        <p className={styles.subtitle}>
          Asigna una nueva contraseña para activar tu cuenta e iniciar sesión
          con ella.
        </p>
        <form
          onSubmit={handleSubmit}
          className={`${styles.onboardingForm} ${styles.centeredForm}`}
        >
          <PasswordInput
            visibility={{
              isVisible: isPasswordVisible,
              setIsVisible: setIsPasswordVisible,
              isToggleAvailable: true,
            }}
            label="Contraseña"
            width="100%"
            inputProps={{
              placeholder: "Ingresa tu contraseña",
              value: formValues.password,
              name: "password",
              onChange: handleChange,
              maxLength: 25,
              type: isPasswordVisible ? "text" : "password",
              required: true,
              autoComplete: "new-password",
            }}
            error={
              errors.password ||
              (error &&
                "La contraseña debe tener al menos 8 caracteres, una mayúscula, una minúscula y un número.")
            }
            touched={touched.password}
          />

          <PasswordInput
            visibility={{
              isVisible: isConfirmPasswordVisible,
              setIsVisible: setIsConfirmPasswordVisible,
              isToggleAvailable: true,
            }}
            label="Confirmar contraseña"
            width="100%"
            inputProps={{
              placeholder: "Ingresa tu contraseña",
              value: formValues.confirmPassword,
              name: "confirmPassword",
              onChange: handleChange,
              maxLength: 25,
              type: isConfirmPasswordVisible ? "text" : "password",
              required: true,
              autoComplete: "new-password",
            }}
            error={
              errors.confirmPassword ||
              (formValues.confirmPassword &&
                formValues.confirmPassword !== formValues.password &&
                "La contraseña no coincide")
            }
            touched={formValues.confirmPassword !== formValues.password}
          />

          <ButtonWithoutIcon
            width="100%"
            isPrimary={true}
            textBttn="Confirmar"
            handleClick={handleSubmit}
            isDisabled={
              !formValues.password ||
              !formValues.confirmPassword ||
              Object.keys(errors).length > 0 ||
              error ||
              isLoading
            }
            isLoading={isLoading}
          />
        </form>
      </section>
      <SlideNotification
        state={toasterContext.state}
        clearStatus={toasterContext.handleToasterClearStatus}
      />
    </div>
  );
};

export default OnboardingAccountActivation;
