export const WeekdaysDictionary = [
  {
    id: 1,
    name: "Domingo",
  },
  {
    id: 2,
    name: "Lunes",
  },
  {
    id: 3,
    name: "Martes",
  },
  {
    id: 4,
    name: "Miércoles",
  },
  {
    id: 5,
    name: "Jueves",
  },
  {
    id: 6,
    name: "Viernes",
  },
  {
    id: 7,
    name: "Sábado",
  },
];

export const GenresDictionary = [
  {
    id: 1,
    name: "Masculino",
  },
  {
    id: 2,
    name: "Femenino",
  },
  // {
  //   id: 3,
  //   name: "Otro",
  // },
];

export const DocumentTypesDictionary = [
  {
    id: 1,
    name: "DNI",
  },
  /* {
    id: 2,
    name: 'Pasaporte',
  },
  {
    id: 3,
    name: 'Cédula',
  }, */
];
