// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ZdWnfJZAJmGepg9kkcaQ {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background-color: #1a0063;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s ease-in-out;
}

.LUE7WoEUNXNRFUrihP_3 {
  font-family: "Mulish";
  font-weight: 600;
  color: #fff;
  font-size: 1.5rem;
  margin-top: 1rem;
}

.i2JpEcp50NTSY6CqARMk {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}

.wQJrCb7mhGtUnDOx5Mv2 {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}
`, "",{"version":3,"sources":["webpack://./src/components/wrappers/LoaderScreen/LoaderScreen.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,eAAe;EACf,MAAM;EACN,OAAO;EACP,aAAa;EACb,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,gCAAgC;AAClC;;AAEA;EACE,qBAAqB;EACrB,gBAAgB;EAChB,WAAW;EACX,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,UAAU;EACV,kBAAkB;EAClB,oBAAoB;AACtB;;AAEA;EACE,UAAU;EACV,mBAAmB;EACnB,mBAAmB;AACrB","sourcesContent":[".loadingContainer {\n  display: flex;\n  flex-direction: column;\n  position: fixed;\n  top: 0;\n  left: 0;\n  z-index: 9999;\n  width: 100%;\n  height: 100%;\n  background-color: #1a0063;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  transition: all 0.5s ease-in-out;\n}\n\n.loadingText {\n  font-family: \"Mulish\";\n  font-weight: 600;\n  color: #fff;\n  font-size: 1.5rem;\n  margin-top: 1rem;\n}\n\n.hidden {\n  opacity: 0;\n  visibility: hidden;\n  pointer-events: none;\n}\n\n.visible {\n  opacity: 1;\n  visibility: visible;\n  pointer-events: all;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loadingContainer": `ZdWnfJZAJmGepg9kkcaQ`,
	"loadingText": `LUE7WoEUNXNRFUrihP_3`,
	"hidden": `i2JpEcp50NTSY6CqARMk`,
	"visible": `wQJrCb7mhGtUnDOx5Mv2`
};
export default ___CSS_LOADER_EXPORT___;
