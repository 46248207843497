import OnboardingAdminPage from "@Pages/views/onboardingAdmin/OnboardingAdmin.page";
import CompletedAdmin from "@Pages/views/onboardingAdmin/views/Completed/Completed";
import { Route, Routes } from "react-router-dom";
// @ts-ignore
import { NotFoundPage } from "@viuti/recursos";

const OnboardingAdmin = () => {
  return (
    <Routes>
      <Route path={"/admin"} element={<OnboardingAdminPage />} />
      <Route path={"/admin/completado"} element={<CompletedAdmin />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default OnboardingAdmin;
