import { ROUTE_EMPLOYEES } from "@Models/routes";
import { postRegisterEmployee_service } from "@Services/post";

// Función para convertir hora de formato AM/PM a formato 24 horas
const convertirHora = (hora) => {
  const [time, modifier] = hora.split(" ");
  let [hours, minutes] = time.split(":");
  if (modifier === "PM" && hours !== "12") {
    hours = parseInt(hours, 10) + 12;
  }
  if (modifier === "AM" && hours === "12") {
    hours = "00";
  }
  return `${hours}:${minutes}`;
};

// Función para construir el payload
const construirPayload = () => {
  const employeeData = {
    password: localStorage.getItem("password"),
    documentType: 2, // Asumiendo que el tipo de documento es 2
    document: localStorage.getItem("documentNumber"),
    name: localStorage.getItem("firstName"),
    lastname: localStorage.getItem("lastName"),
    dateOfBirth: localStorage.getItem("birthDate"), // Cambiar según el formato necesario
    alias: localStorage.getItem("nickname"),
    sex: parseInt(localStorage.getItem("selectedSex")), // Convertir a número
    phone:
      localStorage.getItem("phoneCode") + localStorage.getItem("phoneNumber"), // Concatenar código y número
    uuidOnboarding: localStorage.getItem("uuidOnboarding"), // Asignar el UUID correspondiente
    schedules: [],
    services: JSON.parse(localStorage.getItem("selectedServicesOnboarding")), // Obtener servicios seleccionados
  };

  // Obtener horarios disponibles
  const employeeSchedules = JSON.parse(
    localStorage.getItem("employeeSchedules")
  );
  employeeSchedules.forEach((schedule) => {
    schedule.scheduleList.forEach((item) => {
      if (item.isAvailable) {
        employeeData.schedules.push({
          dayOfTheWeek: schedule.idDay,
          startTime: convertirHora(item.startTime), // Convertir a formato 24 horas
          endTime: convertirHora(item.endTime), // Convertir a formato 24 horas
        });
      }
    });
  });

  return { req: employeeData };
};

export const postRegisterEmployee_adapter = async (
  toasterContext,
  navigate,
  setIsLoading
) => {
  setIsLoading(true);
  const searchParams = new URLSearchParams(window.location.search);
  const token = searchParams.get("token");
  const idEmpleado = searchParams.get("idEmpleado");
  const identifier = searchParams.get("identifier");

  // Construir el payload
  const payload = construirPayload(); // Aquí se utiliza la función para construir el payload

  const response = await postRegisterEmployee_service(payload);
  if (!response.isSuccess) {
    setIsLoading(false);
    toasterContext.handleToasterStateChange({
      message: response.message,
      status: 400,
      loading: false,
    });
    return;
  }
  setIsLoading(false);
  // Limpiar solo los elementos específicos del localStorage
  localStorage.removeItem("birthDate");
  localStorage.removeItem("documentNumber");
  localStorage.removeItem("employeeSchedules");
  localStorage.removeItem("firstName");
  localStorage.removeItem("idEmployee");
  localStorage.removeItem("lastName");
  localStorage.removeItem("nickname");
  localStorage.removeItem("password");
  localStorage.removeItem("phoneCode");
  localStorage.removeItem("phoneNumber");
  localStorage.removeItem("selectedServicesOnboarding");
  localStorage.removeItem("selectedSex");
  localStorage.removeItem("token_onboarding");
  localStorage.removeItem("uuidOnboarding");
  navigate(
    `${ROUTE_EMPLOYEES.COMPLETED}?token=${token}&idEmpleado=${idEmpleado}&identifier=${identifier}`
  );
};
