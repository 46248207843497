import OverlayLogin from "@Pages/views/onboardingEmployee/OverlayLogin/OverlayLogin";
import style from "./Expiration.module.css";

const ExpirationUrl = () => {
  return (
    <div className={style.containerForm} style={{ marginTop: "-0px" }}>
      <div className={style.linkExpiro}>
        <h3>
          El enlace que está utilizando expiró o no es válido, por favor
          solicita una nueva invitación.
        </h3>
      </div>
      <OverlayLogin />
    </div>
  );
};

export default ExpirationUrl;
