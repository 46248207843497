import { IToasterState } from "@Models/interfaces/toaster";
import React, { createContext, useCallback, useMemo, useState } from "react";

export interface IToasterContextProps {
  state: IToasterState;
  clearStatus: () => void;
  handleToasterStateChange: (state: {
    message: string;
    status: number;
    loading: boolean;
  }) => void; // Agregar esta línea
}

export interface IToasterStore {
  state: IToasterState;
  handleToasterStateChange: (state: IToasterState) => void;
  handleToasterClearStatus: () => void;
}

const ToasterContext = createContext<IToasterContextProps | {}>({});

const ToasterProvider = ({ children }) => {
  const [state, setState] = useState<IToasterState>({
    message: "",
    status: 0,
    loading: false,
  });

  const handleToasterStateChange = useCallback((state: IToasterState) => {
    setState(state);
  }, []);

  const handleToasterClearStatus = useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      message: "",
      status: 0,
      loading: false,
    }));
  }, []);

  const contextValue = useMemo(
    () => ({
      state,
      handleToasterStateChange,
      handleToasterClearStatus,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state, handleToasterStateChange, handleToasterClearStatus]
  );

  return (
    <ToasterContext.Provider value={contextValue}>
      {children}
    </ToasterContext.Provider>
  );
};

export { ToasterContext, ToasterProvider };
