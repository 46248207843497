// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ZPHQQh0G5v9t6s5iOQg_ {
  width: 100%;
  height: 100%;
  background-color: #5d36f1;
  position: absolute;
  top: 0;
  z-index: 0;
}

.j7hUWDycIBdlOPR5ZciY img {
  display: block;
  margin: 0 auto;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
}
`, "",{"version":3,"sources":["webpack://./src/pages/views/onboardingEmployee/OverlayLogin/OverlayLogin.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,kBAAkB;EAClB,MAAM;EACN,UAAU;AACZ;;AAEA;EACE,cAAc;EACd,cAAc;EACd,4BAA4B;EAC5B,kCAAkC;EAClC,YAAY;EACZ,aAAa;EACb,iBAAiB;AACnB","sourcesContent":[".overlay {\n  width: 100%;\n  height: 100%;\n  background-color: #5d36f1;\n  position: absolute;\n  top: 0;\n  z-index: 0;\n}\n\n.containerImg img {\n  display: block;\n  margin: 0 auto;\n  background-repeat: no-repeat;\n  background-position: center center;\n  width: 100vw;\n  height: 100vh;\n  object-fit: cover;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"overlay": `ZPHQQh0G5v9t6s5iOQg_`,
	"containerImg": `j7hUWDycIBdlOPR5ZciY`
};
export default ___CSS_LOADER_EXPORT___;
