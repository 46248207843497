import axios from "axios";
import { onboardingAdmin_GW } from "../const";
import { IResponse } from "@Models/interfaces/api";

const resendEmailValidation_service = async (
  email: string
): Promise<IResponse> => {
  const localToken = localStorage.getItem("access_token");
  const url = `${onboardingAdmin_GW}/Onboarding/ReSendEmailVerification`;

  try {
    const response = await axios.post(url, null, {
      headers: {
        Authorization: `Bearer ${localToken}`,
      },
      params: {
        email,
      },
    });

    return {
      isSuccess: true,
      status: response.status,
      message:
        response.data?.message ||
        "Se ha enviado un correo de confirmación a tu email",
      data: response.data.data,
    };
  } catch (error) {
    return {
      isSuccess: false,
      status: error.response?.status || 500,
      message:
        error.response?.data?.message ||
        "Ups! ha ocurrido un error, intenta de nuevo.",
      data: null,
    };
  }
};

export default resendEmailValidation_service;
