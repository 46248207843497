// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BdnPL1dEtuTY1es4S56K {
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
}

.qouEos06XOOdfRDcYHf5 {
  color: #392b75;
  font-family: Mulish;
  font-size: 20px;
  font-weight: 800;
}

.IIRwUiLZk3Md1l739cwS {
  color: #45348e;
  text-align: start;
  font-family: Mulish;
  font-size: 14px;
  font-weight: 400;
}

.cCXd26nKp4Np0t5oBj7R {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
}

.NoHTvNnNFF9_5Ef9BcZJ {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.SVPOS8GhQpqIRaPwiBtP {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  background-color: #dad4f2;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  transition: all 150ms ease-in-out;

  &:hover {
    background-color: #c5bdf0;
  }
}

.SVPOS8GhQpqIRaPwiBtP img {
  width: 16px;
  height: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/FormHeader/MobileFormHeader.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;EACT,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,mBAAmB;EACnB,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,iBAAiB;EACjB,mBAAmB;EACnB,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,QAAQ;EACR,uBAAuB;AACzB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,iCAAiC;;EAEjC;IACE,yBAAyB;EAC3B;AACF;;AAEA;EACE,WAAW;EACX,YAAY;AACd","sourcesContent":[".headerContent {\n  display: flex;\n  flex-direction: row;\n  gap: 12px;\n  align-items: center;\n}\n\n.headerTitle {\n  color: #392b75;\n  font-family: Mulish;\n  font-size: 20px;\n  font-weight: 800;\n}\n\n.headerSubtitle {\n  color: #45348e;\n  text-align: start;\n  font-family: Mulish;\n  font-size: 14px;\n  font-weight: 400;\n}\n\n.headerContainer {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n  align-items: flex-start;\n}\n\n.headerRow {\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.backButtonContainer {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 32px;\n  height: 32px;\n  background-color: #dad4f2;\n  border: none;\n  border-radius: 50%;\n  cursor: pointer;\n  transition: all 150ms ease-in-out;\n\n  &:hover {\n    background-color: #c5bdf0;\n  }\n}\n\n.backButtonContainer img {\n  width: 16px;\n  height: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerContent": `BdnPL1dEtuTY1es4S56K`,
	"headerTitle": `qouEos06XOOdfRDcYHf5`,
	"headerSubtitle": `IIRwUiLZk3Md1l739cwS`,
	"headerContainer": `cCXd26nKp4Np0t5oBj7R`,
	"headerRow": `NoHTvNnNFF9_5Ef9BcZJ`,
	"backButtonContainer": `SVPOS8GhQpqIRaPwiBtP`
};
export default ___CSS_LOADER_EXPORT___;
