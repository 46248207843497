import { adminBaseUrl, baseUrl, config } from "./constGlobals";
import axios from "axios";

export const postServiceEmploye = async (payload) => {
  const localToken = localStorage.getItem("token_onboarding");
  const url = `${baseUrl}/Services/RegisterServices`;

  const response = await axios.post(url, payload, {
    headers: {
      Authorization: `Bearer ${localToken}`,
    },
  });
  return {
    isSuccess: true,
    ...response.data,
  };
};

export const postScheduleService = async (payload) => {
  const localToken = localStorage.getItem("token_onboarding");
  const url = `${baseUrl}/Schedules/RegisterSchedulesOnboarding`;
  try {
    const response = await axios.post(url, payload, {
      headers: {
        Authorization: `Bearer ${localToken}`,
      },
    });
    return {
      isSuccess: true,
      ...response.data,
    };
  } catch (error) {
    return {
      isSuccess: false,
      status: error?.response?.status || 500,
      message:
        error?.response?.data?.message ||
        "Hubo un error al registrar los horarios",
      data: null,
    };
  }
};

export const postGetToken = async (payload) => {
  const localToken = localStorage.getItem("token_onboarding");
  const url = `${baseUrl}/Schedules/RegisterSchedulesOnboarding`;
  try {
    const response = await axios.post(url, payload, {
      headers: {
        Authorization: `Bearer ${localToken}`,
      },
    });
    return response.data;
  } catch (error) {
    return error.response;
  }
};

export const subirArchivo = async (payload) => {
  const url = `${baseUrl}/Registro/subirArchivo`;
  const response = await axios.post(url, payload, config);
  return response.data.data;
};

export const postRegisterExistEmployeeWithoutAccess = async (payload: {
  password: string;
  uuidOnboarding: string;
}) => {
  const localToken = localStorage.getItem("token_onboarding");
  const url = `${baseUrl}/Register/RegisterExistEmployeeWithoutAccess`;
  try {
    const response = await axios.put(url, payload, {
      headers: {
        Authorization: `Bearer ${localToken}`,
      },
    });
    return {
      isSuccess: true,
      status: response?.status || 200,
      message:
        response?.data?.message || "Se completo el registro exitosamente",
      data: response?.data?.data || null,
    };
  } catch (error) {
    return {
      isSuccess: false,
      status: error?.response?.status || 500,
      message:
        error?.response?.data?.message ||
        "Hubo un error al completar el registro",
      data: null,
    };
  }
};

export const postRegisterEmployee_service = async (payload) => {
  const localToken = localStorage.getItem("token_onboarding");
  const url = `${baseUrl}/Register/Register`;
  try {
    const response = await axios.put(url, payload, {
      headers: {
        Authorization: `Bearer ${localToken}`,
      },
    });
    return {
      isSuccess: true,
      status: response?.status || 200,
      message:
        response?.data?.message || "Se completo el registro exitosamente",
      data: response?.data?.data || null,
    };
  } catch (error) {
    return error.response;
  }
};
