import React, { useEffect } from "react";
import style from "./CompletedAccountActivation.module.css";
import templateStyle from "../../onboardingEmployee/PersonalInformation/PersonalInformation.module.css";
// @ts-ignore
import { ButtonWithoutIcon } from "@viuti/recursos";
import FormHeader from "@Components/ui/FormHeader/FormHeader";
import { OperationSuccessIcon } from "@Models/icons";

const CompletedAccountActivation = () => {
  const [windowSize, setWindowSize] = React.useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const signIn = () => {
    location.href = "/";
  };

  return (
    <div className={templateStyle.viewContainer}>
      <div className={templateStyle.viewContent}>
        {windowSize.width > 768 && (
          <FormHeader
            title="Completado"
            subtitle="Has creado tu cuenta exitosamente."
          />
        )}
        <form
          className={`${templateStyle.formContainer} ${style.formContainer}`}
        >
          {windowSize.width <= 768 && (
            <div className={style.headerContainer}>
              <h2>Completado</h2>
              <p>Has creado tu cuenta exitosamente</p>
            </div>
          )}
          <img src={OperationSuccessIcon} alt="completado" />
          <p className={style.formTitle}>¡Listo para comenzar!</p>
          <ButtonWithoutIcon
            width="100%"
            isPrimary={true}
            textBttn="Ir a Prikly"
            handleClick={signIn}
          />
        </form>
      </div>
    </div>
  );
};

export default CompletedAccountActivation;
