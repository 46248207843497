import StepsHeader from "@Components/wrappers/StepsHeader/StepsHeader";
import style from "./OnboardingAdmin.module.css";
// @ts-ignore
import { SlideNotification } from "@viuti/recursos";
import AccountForm from "./views/AccountForm/AccountForm";
import { useContext, useState } from "react";
import { CheckIcon, CredentialCardIcon, TrophyIcon } from "@Models/icons";
import EmailConfirmation from "./views/EmailConfirmation/EmailConfirmation";
import useTheApiCalls from "./hooks/useTheApiCalls";
import LoaderScreen from "@Components/wrappers/LoaderScreen/LoaderScreen";
import { IToasterStore, ToasterContext } from "@Hooks/useToasterContext";

export interface IFormValues {
  email: string;
  password: string;
  personalName: string;
  personalSurname: string;
  mobilePhone: string;
  businessName: string;
  businessWebsite: string;
  businessCountry: string;
  businessDirection: string;
  businessDepartment: string;
}

const OnboardingAdminPage = () => {
  const [currentView, setCurrentView] = useState<number>(0);
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formValues, setFormValues] = useState<IFormValues>({
    email: "",
    password: "",
    personalName: "",
    personalSurname: "",
    mobilePhone: "",
    businessName: "",
    businessWebsite: "",
    businessCountry: "",
    businessDirection: "",
    businessDepartment: "",
  });

  const toasterContext = useContext(ToasterContext) as IToasterStore;

  const { sendEmailAgain, continueWithTheOnboarding } = useTheApiCalls(
    formValues,
    setIsLoading,
    setCurrentView,
    toasterContext.handleToasterStateChange
  );

  return (
    <main className={style.onboardingAdmin__mainContent}>
      <header className={style.onboardingAdmin__header}>
        <StepsHeader
          currentStep={currentStep}
          steps={[
            {
              icon: CredentialCardIcon,
              text: "Datos",
            },
            {
              icon: CheckIcon,
              text: "Validación",
            },
            {
              icon: TrophyIcon,
              text: "Completado",
            },
          ]}
        />
      </header>
      <section className={style.onboardingAdmin__section}>
        <LoaderScreen visible={isLoading} />
        {currentView === 0 && (
          <AccountForm
            setFormValues={setFormValues}
            formValues={formValues}
            isLoading={isLoading}
            continueWithTheOnboarding={continueWithTheOnboarding}
          />
        )}
        {currentView === 1 && (
          <EmailConfirmation
            sendEmailAgain={sendEmailAgain}
            currentEmail={formValues.email}
            setCurrentStep={setCurrentStep}
          />
        )}
        <SlideNotification
          state={toasterContext.state}
          clearStatus={toasterContext.handleToasterClearStatus}
        />
      </section>
    </main>
  );
};

export default OnboardingAdminPage;
