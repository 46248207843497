import { validationScheme } from "./validationScheme";

export const handleInputChangePasswords = (
  e,
  values,
  setValues,
  touched,
  setTouched,
  errors,
  setErrors
) => {
  const { name, value } = e.target;

  if (name === "password") {
    setValues({ ...values, password: value });
    localStorage.setItem("password", value);
    setTouched({ ...touched, password: true });
  } else if (name === "confirmPassword") {
    setValues({ ...values, confirmPassword: value });
    setTouched({ ...touched, confirmPassword: true });
  }
  validationScheme(e, values, errors, setErrors);
};
