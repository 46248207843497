export const validationScheme = (e: any, errors, setErrors: Function) => {
  const { name, value } = e.target;
  const onlyLettersRegex = /^[a-zA-ZáéíóúÁÉÍÓÚäëïöüÄËÏÖÜãẽĩõũÃẼĨÕñÑ\s]+$/g;

  switch (name) {
    case "documentNumber":
      if (value.length === 0) {
        setErrors({ ...errors, documentNumber: "Campo requerido" });
      } else if (isNaN(value)) {
        setErrors({ ...errors, documentNumber: "El campo debe ser un número" });
      } else {
        setErrors({ ...errors, documentNumber: "" });
      }
      break;
    case "firstName":
      if (value.length === 0) {
        setErrors({ ...errors, firstName: "Campo requerido" });
      } else if (value.length < 3) {
        setErrors({
          ...errors,
          firstName: "El nombre debe tener al menos 3 caracteres",
        });
      } else if (value.length > 20) {
        setErrors({
          ...errors,
          firstName: "El nombre debe tener menos de 20 caracteres",
        });
      } else if (!onlyLettersRegex.test(value)) {
        setErrors({
          ...errors,
          firstName: "El nombre debe contener solo letras",
        });
      } else {
        setErrors({ ...errors, firstName: "" });
      }
      break;

    case "lastName":
      if (value.length === 0) {
        setErrors({ ...errors, lastName: "Campo requerido" });
      } else if (value.length < 3) {
        setErrors({
          ...errors,
          lastName: "El apellido debe tener al menos 3 caracteres",
        });
      } else if (value.length > 20) {
        setErrors({
          ...errors,
          lastName: "El apellido debe tener menos de 20 caracteres",
        });
      } else if (!onlyLettersRegex.test(value)) {
        setErrors({
          ...errors,
          lastName: "El apellido debe contener solo letras",
        });
      } else {
        setErrors({ ...errors, lastName: "" });
      }
      break;
    case "birthDate":
      if (value.length === 0) {
        setErrors({ ...errors, birthDate: "Campo requerido" });
        // check if date is before today
      } else if (new Date(value) > new Date()) {
        setErrors({
          ...errors,
          birthDate:
            "La fecha de nacimiento no puede ser mayor a la fecha actual",
        });
        // chekc if date is after 18 years ago
      } else if (
        new Date(value) >
        new Date(new Date().setFullYear(new Date().getFullYear() - 18))
      ) {
        setErrors({
          ...errors,
          birthDate: "La fecha de nacimiento no debe ser menor a 18 años",
        });
      } else {
        setErrors({ ...errors, birthDate: "" });
      }
      break;
    case "nickname":
      if (value.length === 0) {
        setErrors({ ...errors, nickname: "Campo requerido" });
      } else if (value.length < 3) {
        setErrors({
          ...errors,
          nickname: "El apodo debe tener al menos 3 caracteres",
        });
      } else if (value.length > 20) {
        setErrors({
          ...errors,
          nickname: "El apodo debe tener menos de 20 caracteres",
        });
      } else if (!onlyLettersRegex.test(value)) {
        setErrors({
          ...errors,
          nickname: "El apodo debe contener solo letras",
        });
      } else {
        setErrors({ ...errors, nickname: "" });
      }
      break;
    case "selectedSex":
      if (value.length === 0) {
        setErrors({ ...errors, selectedSex: "Campo requerido" });
      } else {
        setErrors({ ...errors, selectedSex: "" });
      }
      break;
  }
};
