/* eslint-disable no-use-before-define */
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import style from "./PersonalInformation.module.css";

// Components
import StepsHeader from "@Components/wrappers/StepsHeader/StepsHeader";
import {
  ButtonWithoutIcon,
  DateInput,
  SelectInput,
  TextInput,
  PasswordInput,
  //@ts-ignore
} from "@viuti/recursos";

import { handleSubmit } from "../PersonalInformationTwo/functions/handleSubmit";
import { ToasterContext } from "@Hooks/useToasterContext";

// Dictionaries
import {
  DocumentTypesDictionary,
  GenresDictionary,
} from "@Models/dictionaries";

// Functions
import { nullToEmpty } from "@Utilities/nullToEmpty";
import { handleInputChange } from "./functions/handleInputChange";
import { handleResizeFormFields } from "./functions/handleResizeFormFields";
import FormHeader from "@Components/ui/FormHeader/FormHeader";
import CustomSelect from "../PersonalInformationTwo/CustomSelect";
import { handleInputChangePhone } from "../PersonalInformationTwo/functions/handleInputChangePhone";
import { handleInputChangePasswords } from "../MainView/functions/handleInputChangePasswords";
import adaptInfoPerson from "@Adapters/admin/adaptInfoPerson.adapter";
import { ROUTE_EMPLOYEES } from "@Models/routes";

const PersonalInformation = () => {
  const navigate = useNavigate();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const toasterContext: any = useContext(ToasterContext);

  // Obtener el token y otros parámetros de la URL
  const searchParams = new URLSearchParams(window.location.search);
  const token = searchParams.get("token");
  const idEmpleado = searchParams.get("idEmpleado");
  const identifier = searchParams.get("identifier");

  const [phoneValues, setPhoneValues] = useState({
    phoneCode: Number(localStorage.getItem("phoneCode")) || 51,
    phoneNumber: nullToEmpty(localStorage.getItem("phoneNumber")),
  });

  const [values, setValues] = useState({
    documentType: "DNI",
    documentNumber: nullToEmpty(localStorage.getItem("documentNumber")),
    firstName: nullToEmpty(localStorage.getItem("firstName")),
    lastName: nullToEmpty(localStorage.getItem("lastName")),
    birthDate: nullToEmpty(localStorage.getItem("birthDate")),
    nickname: nullToEmpty(localStorage.getItem("nickname")),
    selectedSex: nullToEmpty(localStorage.getItem("selectedSex")),
    password: nullToEmpty(localStorage.getItem("password")),
    confirmPassword: nullToEmpty(localStorage.getItem("password")),
  });

  const [isLoading, setIsLoading] = useState(false);

  const areRequiredFieldsFilled =
    Object.values(values).every((value) => value !== "") &&
    phoneValues.phoneNumber.length === 9;

  const [errors, setErrors] = useState({
    documentType: "",
    documentNumber: "",
    firstName: "",
    lastName: "",
    birthDate: "",
    nickname: "",
    selectedSex: "",
    isUnderage: "",
    password: "",
    confirmPassword: "",
    mobilePhone: "",
  });

  const hasErrors = Object.values(errors).some((value) => value !== "");

  const [touched, setTouched] = useState({
    documentType: true,
    documentNumber: false,
    firstName: false,
    lastName: false,
    birthDate: false,
    nickname: false,
    selectedSex: false,
    password: false,
    confirmPassword: false,
  });

  const documentTypesListNames = DocumentTypesDictionary.map(
    (type) => type.name
  );

  const formField = useRef(null);
  const [documentSelectorWidth, setDocumentSelectorWidth] = useState(100);

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  useEffect(() => {
    // si el telefono es mayor a 0 y menor a 9 caracteres entonces se muestra el error
    if (
      phoneValues.phoneNumber.length > 0 &&
      phoneValues.phoneNumber.length !== 9
    ) {
      setErrors({
        ...errors,
        mobilePhone: "El número de teléfono debe tener 9 dígitos",
      });
      return;
    } else {
      setErrors({
        ...errors,
        mobilePhone: "",
      });
    }
    // eslint-disable-next-line
  }, [phoneValues.phoneNumber]);
  useEffect(() => {
    handleResizeFormFields(formField, setDocumentSelectorWidth);

    const handleResize = () => {
      handleResizeFormFields(formField, setDocumentSelectorWidth);
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    (async () => {
      if (values.documentNumber.length === 8) {
        adaptInfoPerson(values.documentNumber, setValues);
      }
    })();
    // eslint-disable-next-line
  }, [values.documentNumber]);

  const handleSubmit = async () => {
    // ... lógica de envío existente ...

    // Navegar a la siguiente ruta incluyendo todos los parámetros
    navigate(
      `${ROUTE_EMPLOYEES.SERVICES}?token=${token}&idEmpleado=${idEmpleado}&identifier=${identifier}`
    );
  };

  return (
    <main className={style.viewContainer}>
      <StepsHeader currentStep={1} responsive />

      <div className={style.viewContent}>
        {windowSize.width > 768 && (
          <FormHeader
            title="¡Bienvenido a Prikly!"
            subtitle="Inicia tu registro ingresando tus datos personales"
          />
        )}
        <form
          className={style.formContainer}
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          {windowSize.width <= 768 && (
            <div className={style.headerContainer}>
              <h2>¡Bienvenido a Prikly!</h2>
              <p>Inicia tu registro ingresando tus datos personales</p>
            </div>
          )}
          <div className={style.formFieldsContainer}>
            <div className={style.leftColumnForm}>
              <div className={style.documentsFieldContainer} ref={formField}>
                <SelectInput
                  label="Tipo"
                  value={values.documentType}
                  handleChange={(e) =>
                    handleInputChange(
                      e,
                      setValues,
                      touched,
                      setTouched,
                      errors,
                      setErrors
                    )
                  }
                  name={"documentType"}
                  width={`${documentSelectorWidth}px`}
                  items={documentTypesListNames}
                  required
                  flex={0}
                />
                <TextInput
                  label="Número de documento"
                  inputProps={{
                    placeholder: "Ingrese su número de documento",
                    value: values.documentNumber,
                    name: "documentNumber",
                    onChange: (e) =>
                      handleInputChange(
                        e,
                        setValues,
                        touched,
                        setTouched,
                        errors,
                        setErrors
                      ),
                    onBlur: (e) =>
                      handleInputChange(
                        e,
                        setValues,
                        touched,
                        setTouched,
                        errors,
                        setErrors
                      ),
                    maxlength: "8",
                  }}
                  name="documentNumber"
                  error={errors.documentNumber}
                  touched={touched.documentNumber}
                  flex={1}
                  required
                />
              </div>
              <TextInput
                label="Nombre(s)"
                inputProps={{
                  placeholder: "Ingrese su/s nombre/s",
                  value: values.firstName,
                  name: "firstName",
                  onChange: (e) =>
                    handleInputChange(
                      e,
                      setValues,
                      touched,
                      setTouched,
                      errors,
                      setErrors
                    ),
                  onBlur: (e) =>
                    handleInputChange(
                      e,
                      setValues,
                      touched,
                      setTouched,
                      errors,
                      setErrors
                    ),
                  maxlength: "30",
                  onKeyPress: (e) => {
                    // Evitar la entrada de números
                    if (/\d/.test(e.key)) {
                      e.preventDefault();
                    }
                  },
                }}
                error={errors.firstName}
                touched={touched.firstName}
                required
              />
              <TextInput
                label="Apellido(s)"
                inputProps={{
                  placeholder: "Ingrese su/s apellido/s",
                  value: values.lastName,
                  name: "lastName",
                  onChange: (e) =>
                    handleInputChange(
                      e,
                      setValues,
                      touched,
                      setTouched,
                      errors,
                      setErrors
                    ),
                  onBlur: (e) =>
                    handleInputChange(
                      e,
                      setValues,
                      touched,
                      setTouched,
                      errors,
                      setErrors
                    ),
                  maxlength: "30",
                  onKeyPress: (e) => {
                    // Evitar la entrada de números
                    if (/\d/.test(e.key)) {
                      e.preventDefault();
                    }
                  },
                }}
                error={errors.lastName}
                touched={touched.lastName}
                required
              />
            </div>
            <div className={style.rightColumnForm}>
              <DateInput
                label="Fecha de nacimiento"
                inputProps={{
                  value: values.birthDate,
                  name: "birthDate",
                  onChange: (e) =>
                    handleInputChange(
                      e,
                      setValues,
                      touched,
                      setTouched,
                      errors,
                      setErrors
                    ),
                  onBlur: (e) =>
                    handleInputChange(
                      e,
                      setValues,
                      touched,
                      setTouched,
                      errors,
                      setErrors
                    ),
                  min: new Date(
                    new Date().setFullYear(new Date().getFullYear() - 100)
                  )
                    .toISOString()
                    .split("T")[0],
                  max: new Date(
                    new Date().setFullYear(new Date().getFullYear() - 14)
                  )
                    .toISOString()
                    .split("T")[0],
                }}
                error={errors.birthDate}
                touched={touched.birthDate}
                required
              />
              <TextInput
                label="¿Cómo prefieres que te llamen?"
                inputProps={{
                  placeholder: "Ingrese su alias",
                  value: values.nickname,
                  name: "nickname",
                  onChange: (e) =>
                    handleInputChange(
                      e,
                      setValues,
                      touched,
                      setTouched,
                      errors,
                      setErrors
                    ),
                  onBlur: (e) =>
                    handleInputChange(
                      e,
                      setValues,
                      touched,
                      setTouched,
                      errors,
                      setErrors
                    ),
                  maxlength: "15",
                }}
                error={errors.nickname}
                touched={touched.nickname}
                required
              />
              <div className={style.radioGroupContainer}>
                {/* eslint-disable-next-line */}
                <label className={style.radioGroupLabel}>
                  Sexo
                  <p className={style.inputRequired}>*</p>
                </label>
                <div className={style.radioGroupContent}>
                  {GenresDictionary.map((sex) => (
                    <div className={style.radioInputContainer} key={sex.id}>
                      <input
                        style={{
                          borderColor: errors.selectedSex ? "#FF2E2E" : "",
                        }}
                        type="radio"
                        value={sex.id.toString()}
                        id={sex.id.toString()}
                        checked={values.selectedSex === sex.id.toString()}
                        name="selectedSex"
                        onChange={(e) =>
                          handleInputChange(
                            e,
                            setValues,
                            touched,
                            setTouched,
                            errors,
                            setErrors
                          )
                        }
                        className={style.customCheckbox}
                      />
                      <label htmlFor={sex.id.toString()}>{sex.name}</label>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className={style.containerThree}>
              <div className={style.countrySelectContainer}>
                <p>
                  Teléfono móvil <span className={style.inputRequired}>*</span>
                </p>
                <CustomSelect
                  phoneValues={{
                    phoneCode: phoneValues.phoneCode,
                    phoneNumber: phoneValues.phoneNumber,
                  }}
                  setPhoneValues={setPhoneValues}
                  handleInputChange={(e) =>
                    handleInputChangePhone(e, phoneValues, setPhoneValues)
                  }
                  error={errors.mobilePhone}
                />
              </div>
              <div className={style.containerFour}>
                <div className={style.inputContainer}>
                  <PasswordInput
                    visibility={{
                      isVisible: isPasswordVisible,
                      setIsVisible: setIsPasswordVisible,
                      isToggleAvailable: true,
                    }}
                    label="Contraseña"
                    inputProps={{
                      placeholder: "Ingresa tu contraseña",
                      value: values.password,
                      name: "password",
                      onChange: (e) =>
                        handleInputChangePasswords(
                          e,
                          values,
                          setValues,
                          touched,
                          setTouched,
                          errors,
                          //@ts-ignore
                          setErrors
                        ),
                      maxLength: 25,
                      autoComplete: "new-password",
                      type: isPasswordVisible ? "text" : "password",
                      required: true,
                    }}
                    error={errors.password}
                    touched={touched.password}
                  />
                </div>
                <div className={style.inputContainer}>
                  <PasswordInput
                    visibility={{
                      isVisible: isPasswordVisible,
                      setIsVisible: setIsPasswordVisible,
                      isToggleAvailable: true,
                    }}
                    label="Confirmar contraseña"
                    inputProps={{
                      placeholder: "Repite tu contraseña",
                      value: values.confirmPassword,
                      name: "confirmPassword",
                      onChange: (e) =>
                        handleInputChangePasswords(
                          e,
                          values,
                          setValues,
                          touched,
                          setTouched,
                          errors,
                          //@ts-ignore
                          setErrors
                        ),
                      autoComplete: "new-password",
                      maxLength: 25,
                      type: isPasswordVisible ? "text" : "password",
                      required: true,
                    }}
                    error={errors.confirmPassword}
                    touched={touched.confirmPassword}
                    required
                  />
                </div>
              </div>
            </div>
          </div>
          <ButtonWithoutIcon
            isPrimary
            textBttn={"Continuar"}
            handleClick={handleSubmit} // Cambiar a la función handleSubmit
            width="100%"
            isDisabled={hasErrors || !areRequiredFieldsFilled || isLoading}
            isLoading={isLoading}
          />
        </form>
      </div>
    </main>
  );
};

export default PersonalInformation;
