import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { ROUTE_EMPLOYEES } from "@Models/routes";

// Views
import PersonalInformation from "@Pages/views/onboardingEmployee/PersonalInformation/PersonalInformation";
import PersonalInformationTwo from "@Pages/views/onboardingEmployee/PersonalInformationTwo/PersonalInformationTwo";
import ProfilePicture from "@Pages/views/onboardingEmployee/ProfilePicture/ProfilePicture";
import Services from "@Pages/views/onboardingEmployee/Services/Services";
import Schedules from "@Pages/views/onboardingEmployee/Schedules/Schedules";
import Completed from "@Pages/views/onboardingEmployee/Completed/Completed";
import { useContext, useEffect, useState } from "react";
import { checkUserTokenAdapter } from "@Adapters/checkUserToken.adapter";
import { ToasterContext } from "@Hooks/useToasterContext";
import {
  STATUS_OK,
  STATUS_PENDING,
  STATUS_UNAUTHORIZED,
} from "@Models/httpsStatus";

// Components
import LoaderScreen from "@Components/wrappers/LoaderScreen/LoaderScreen";
// @ts-ignore
import { NotFoundPage, SlideNotification } from "@viuti/recursos";
import OverlayLogin from "@Pages/views/onboardingEmployee/OverlayLogin/OverlayLogin";

//@ts-ignore
import style from "../pages/views/onboardingEmployee/MainView/MainView.module.css";
import OnboardingAccountActivation from "@Pages/views/onboardingAccountActivation/onboardingAccountActivation";

const OnboardingEmployee = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [tokenStatus, setTokenStatus] = useState(0);
  const [typeOnboardingEmployee, setTypeOnboardingEmployee] = useState(0); // Agregar estado para el tipo de onboarding

  const toasterContext: any = useContext(ToasterContext);

  useEffect(() => {
    if (tokenStatus === STATUS_PENDING) {
      checkUserTokenAdapter(location, navigate).then((data) => {
        setTokenStatus(data.status);
        setTypeOnboardingEmployee(data.data.typeOnboardingEmployee); // Guardar el tipo de onboarding

        if (
          data.status === STATUS_OK &&
          location.pathname !== ROUTE_EMPLOYEES.COMPLETED
        ) {
          const isOnboardingCompleted = localStorage.getItem(
            "isOnboardingCompleted"
          );
          if (isOnboardingCompleted === "true") {
            navigate(ROUTE_EMPLOYEES.COMPLETED);
          }
        }
      });
      // In case the user navigate around the internal routes it also checks if the onboarding was completed
    } else if (
      tokenStatus === STATUS_OK &&
      location.pathname !== ROUTE_EMPLOYEES.COMPLETED
    ) {
      const isOnboardingCompleted = localStorage.getItem(
        "isOnboardingCompleted"
      );
      if (isOnboardingCompleted === "true") {
        navigate(ROUTE_EMPLOYEES.COMPLETED);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const route = location.pathname.split("/")[1];

  return (
    <>
      <LoaderScreen visible={tokenStatus === STATUS_PENDING} />
      {/* {0 ? ( */}
      {tokenStatus !== STATUS_OK && route !== "admin" ? (
        <div className={style.containerForm} style={{ marginTop: "-0px" }}>
          <div className={style.linkExpiro}>
            <h3>
              El enlace que está utilizando expiró o no es válido, por favor
              solicita una nueva invitación.
            </h3>
          </div>
          <OverlayLogin />
        </div>
      ) : (
        <>
          {typeOnboardingEmployee === 1 ? (
            <Routes>
              <Route
                path={ROUTE_EMPLOYEES.ROUTE_MAIN_VIEW}
                element={<OnboardingAccountActivation />}
              />
            </Routes>
          ) : (
            <Routes>
              <Route
                path={ROUTE_EMPLOYEES.ROUTE_MAIN_VIEW}
                element={<PersonalInformation />}
              />
              <Route
                path={ROUTE_EMPLOYEES.PERSONAL_INFORMATION}
                element={<PersonalInformation />}
              />
              <Route
                path={ROUTE_EMPLOYEES.PERSONAL_INFORMATION_TWO}
                element={<PersonalInformationTwo />}
              />

              <Route path={ROUTE_EMPLOYEES.SERVICES} element={<Services />} />
              <Route
                path={ROUTE_EMPLOYEES.SCHENDULES}
                element={<Schedules />}
              />
              <Route path={ROUTE_EMPLOYEES.COMPLETED} element={<Completed />} />
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          )}
        </>
      )}
      <SlideNotification
        state={toasterContext.state}
        clearStatus={toasterContext.handleToasterClearStatus}
      />
    </>
  );
};

export default OnboardingEmployee;
