export const STATUS_PENDING = 0;
export const STATUS_OK = 200;
export const STATUS_CREATED = 201;
export const STATUS_NO_CONTENT = 204;
export const STATUS_BAD_REQUEST = 400;
export const STATUS_UNAUTHORIZED = 401;
export const STATUS_NOT_FOUND = 404;
export const STATUS_INTERNAL_SERVER_ERROR = 500;
export const STATUS_SERVICE_UNAVAILABLE = 503;
export const STATUS_GATEWAY_TIMEOUT = 504;
export const STATUS_CONFLICT = 409;
export const STATUS_FORBIDDEN = 403;
export const STATUS_PRECONDITION_FAILED = 412;
export const STATUS_UNPROCESSABLE_ENTITY = 422;
export const STATUS_TOO_MANY_REQUESTS = 429;
export const STATUS_NOT_IMPLEMENTED = 501;
