// Styles
import style from "./MobileFormHeader.module.css";

// Components
// @ts-ignore
import { ButtonWithoutIcon } from "@viuti/recursos";
import { AltArrowLeftIcon } from "@Models/icons";

interface MobileFormHeaderProps {
  title: string;
  subtitle: string;
  onBack: () => void;
  onNext: () => void;
  isNextAvailable?: boolean;
  isLoading: boolean;
}

const MobileFormHeader = ({
  title,
  subtitle,
  onBack,
  onNext,
  isNextAvailable = true,
  isLoading,
}: MobileFormHeaderProps) => {
  return (
    <div className={style.headerContainer}>
      <div className={style.headerRow}>
        <div className={style.headerContent}>
          {onBack && (
            <button className={style.backButtonContainer} onClick={onBack}>
              <img src={AltArrowLeftIcon} alt="" />
            </button>
          )}
          <h2 className={style.headerTitle}>{title}</h2>
        </div>
        {onNext && (
          <ButtonWithoutIcon
            isPrimary
            textBttn={"Siguiente"}
            handleClick={onNext}
            width={"135px"}
            isDisabled={!isNextAvailable || isLoading}
            isloading={isLoading}
          />
        )}
      </div>
      <p className={style.headerSubtitle}>{subtitle}</p>
    </div>
  );
};

export default MobileFormHeader;
