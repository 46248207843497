// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.y9mQnhooDkjn_K0gl_jZ {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  max-width: 655px;
}

.y9mQnhooDkjn_K0gl_jZ h2 {
  color: #392b75;
  text-align: center;
  font-family: "Mulish", sans-serif;
  font-size: var(--font-size-h3);
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: 0.233px;
}

.y9mQnhooDkjn_K0gl_jZ p {
  color: #45348e;
  text-align: center;
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.233px;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/FormHeader/FormHeader.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,QAAQ;EACR,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,iCAAiC;EACjC,8BAA8B;EAC9B,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,iCAAiC;EACjC,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,uBAAuB;AACzB","sourcesContent":[".headerContainer {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n  align-items: center;\n  max-width: 655px;\n}\n\n.headerContainer h2 {\n  color: #392b75;\n  text-align: center;\n  font-family: \"Mulish\", sans-serif;\n  font-size: var(--font-size-h3);\n  font-style: normal;\n  font-weight: 800;\n  line-height: normal;\n  letter-spacing: 0.233px;\n}\n\n.headerContainer p {\n  color: #45348e;\n  text-align: center;\n  font-family: \"Mulish\", sans-serif;\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n  letter-spacing: 0.233px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerContainer": `y9mQnhooDkjn_K0gl_jZ`
};
export default ___CSS_LOADER_EXPORT___;
