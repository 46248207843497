import style from "./StepsHeader.module.css";

// Icons
import {
  ClockIcon,
  CredentialCardIcon,
  ServiceIcon,
  TrophyIcon,
} from "@Models/icons";

interface StepBubbleProps {
  icon: string;
  text: string;
  isCurrent: boolean;
  isComplete?: boolean;
  customStyle?: any;
}

const StepBubble = ({
  icon,
  text,
  isCurrent,
  isComplete,
  customStyle,
}: StepBubbleProps) => (
  <div
    className={`${style.stepBubble} ${
      isCurrent || isComplete ? style.stepBubbleIsCurrent : ""
    }`}
    style={customStyle}
  >
    <img src={icon} alt={text} />
    <p
      className={`${style.stepBubbleName} ${
        isCurrent ? style.currentStepTitle : ""
      }`}
    >
      {text}
    </p>
  </div>
);

const StepsHeader = ({
  currentStep,
  steps = [
    {
      icon: CredentialCardIcon,
      text: "Datos",
    },
    {
      icon: ServiceIcon,
      text: "Servicios",
    },
    {
      icon: ClockIcon,
      text: "Horarios de trabajo",
    },
    {
      icon: TrophyIcon,
      text: "Completado",
    },
  ],
  responsive = false,
}) => {
  return (
    <div
      className={`${style.onboardingStepContainer} ${
        !responsive ? style.onboardingAdminStepContainer : ""
      }`}
    >
      <div className={style.stepsHeaderContainer}>
        {steps?.map((step, index) => {
          const uniqueKey = `${step.text}-${index}`;
          return (
            <span key={uniqueKey} className={style.step}>
              <StepBubble
                icon={step.icon}
                text={step.text}
                isComplete={currentStep > index + 1}
                isCurrent={currentStep === index + 1}
              />
              {index !== steps.length - 1 && (
                <div className={style.bubbleBar}>
                  <div className={style.bar}>
                    <span
                      className={`${
                        currentStep > index ? style.fullfillBar : style.emptyBar
                      }`}
                    />
                    <span
                      className={`${
                        currentStep > index + 1
                          ? style.fullfillBar
                          : style.emptyBar
                      }`}
                    />
                  </div>
                </div>
              )}
            </span>
          );
        })}
      </div>
    </div>
  );
};

export default StepsHeader;
