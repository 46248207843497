import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// Styles
import templateStyle from "../PersonalInformation/PersonalInformation.module.css";
import style from "./Schedules.module.css";

// Components
// @ts-ignore
import { ButtonWithoutIcon, SchedulesWrapper } from "@viuti/recursos";
import StepsHeader from "@Components/wrappers/StepsHeader/StepsHeader";

// Models
import { initialSchedulesState } from "@Models/initialSchedulesState";

// Hooks
import { ToasterContext } from "@Hooks/useToasterContext";
import FormHeader from "@Components/ui/FormHeader/FormHeader";
import MobileFormHeader from "@Components/ui/FormHeader/MobileFormHeader";
import { registerSchedulesAdapter } from "@Adapters/registerSchedules.adapter";
import { ROUTE_EMPLOYEES } from "@Models/routes";
import { postRegisterEmployee_adapter } from "@Adapters/employee/registerEmployee.adapter";

const Schedules = () => {
  const navigate = useNavigate();
  const toasterContext: any = useContext(ToasterContext);
  // const [isDisabled, setIsDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const searchParams = new URLSearchParams(window.location.search);
  const token = searchParams.get("token");
  const idEmpleado = searchParams.get("idEmpleado");
  const identifier = searchParams.get("identifier");

  const handleSubmit = async () => {
    await postRegisterEmployee_adapter(toasterContext, navigate, setIsLoading);
  };

  const handleBack = () => {
    navigate(
      `${ROUTE_EMPLOYEES.SERVICES}?token=${token}&idEmpleado=${idEmpleado}&identifier=${identifier}`
    );
  };

  const schedulesLocalStorage = localStorage.getItem("employeeSchedules");
  const [employeeSchedules, setEmployeeSchedules] = useState(
    schedulesLocalStorage
      ? JSON.parse(schedulesLocalStorage)
      : initialSchedulesState // Parsear el JSON si existe
  ); // Inicializar antes de usar
  useEffect(() => {
    // Guardar horarios en localStorage cada vez que cambien
    localStorage.setItem(
      "employeeSchedules",
      JSON.stringify(employeeSchedules)
    );
  }, [employeeSchedules]); // Escuchar cambios en employeeSchedules

  const onDeleteSchedule = (idSchedule: number) => {
    const removeScheduleById = (arr, idScheduleToRemove: number) => {
      return arr.map((day) => {
        return {
          ...day,
          scheduleList: day.scheduleList.filter((schedule) => {
            return schedule.idSchedule !== idScheduleToRemove;
          }),
        };
      });
    };

    const newArray = removeScheduleById(employeeSchedules, idSchedule);
    setEmployeeSchedules(newArray);
  };

  // useEffect(() => {
  //   const isAnyScheduleActive = employeeSchedules
  //     .map((e) => {
  //       return e.scheduleList.map((s) => {
  //         return s.isAvailable;
  //       });
  //     })
  //     .flat()
  //     .includes(true);

  //   if (isAnyScheduleActive) setIsDisabled(false);
  //   else setIsDisabled(true);
  // }, [employeeSchedules]);

  return (
    <div className={templateStyle.viewContainer}>
      <StepsHeader currentStep={3} responsive />
      <div className={templateStyle.viewContent}>
        <FormHeader title="Horarios" subtitle="Indica tu horario habitual." />
        <form
          className={`${templateStyle.formContainer} ${style.formContainer}`}
          onSubmit={(e) => e.preventDefault()}
        >
          <div className={style.schedulesContainer}>
            <SchedulesWrapper
              schedules={employeeSchedules}
              setSchedules={setEmployeeSchedules}
              scheduleActions={{ onDelete: onDeleteSchedule }}
            />
          </div>

          <div className={style.actionButtonsContainer}>
            <ButtonWithoutIcon
              isPrimary={false}
              textBttn={"Regresar"}
              handleClick={handleBack} // Función para regresar
            />
            <ButtonWithoutIcon
              isPrimary
              textBttn={"Continuar"}
              isDisabled={isLoading}
              handleClick={handleSubmit}
              isLoading={isLoading}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default Schedules;
