export const navigateToHomeAdapter = async (props: ILoginServiceParams) => {
  const account_info = {
    employee: {
      id: props.IdEmpleado,
      name: props.Name,
      lastName: props.LastName,
      email: props.Email,
      profileImage: "",
      alias: props.Name,
    },
    lounge: {
      id: props.LocalId,
      name: props.LocalName,
    },
    company: {
      id: props.CompanyId,
      name: props.CompanyName,
    },
    role: {
      id: props.IdRole,
      name:
        props.Role?.charAt(0).toUpperCase() +
        props.Role?.slice(1).toLowerCase(),
    },
    plan: {
      id: props.PlanId,
      name: props.PlanName,
    },
  };

  const selectedLocal = {
    id: props.LocalId,
    nombre: props.LocalName,
  };

  localStorage.setItem("access_token", props.access_token);
  localStorage.setItem("account_info", JSON.stringify(account_info));
  localStorage.setItem("selectedLocal", JSON.stringify(selectedLocal));

  window.location.href = `/cajas`;
};
