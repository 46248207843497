import axios from "axios";
import { onboardingAdmin_GW } from "../const";
import { IResponse } from "@Models/interfaces/api";

const createAdminUser_service = async (props: any): Promise<IResponse> => {
  const localToken = localStorage.getItem("access_token");
  const url = `${onboardingAdmin_GW}/Onboarding/OnboardingNewClient`;

  try {
    const response = await axios.post(url, props, {
      headers: {
        Authorization: `Bearer ${localToken}`,
      },
    });

    return {
      isSuccess: true,
      ...response.data,
    };
  } catch (error) {
    return {
      isSuccess: false,
      status: error.response?.status || 500,
      message:
        error.response?.data?.message ||
        "Ups! ha ocurrido un error, intenta de nuevo.",
      data: null,
    };
  }
};

export default createAdminUser_service;
