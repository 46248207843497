import { IToasterState } from "@Models/interfaces/toaster";
import resendEmailValidation_service from "@Services/admin/emailValidation/resendEmailValidation";

const resendEmailValidation = async (
  email: string,
  handleToasterStateChange: (state: IToasterState) => void
) => {
  const resolve = await resendEmailValidation_service(email);

  handleToasterStateChange({
    message: resolve.message,
    status: resolve.status,
    loading: false,
  });
};

export default resendEmailValidation;
