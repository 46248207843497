import React, { useContext, useEffect, useState } from "react";
import StepsHeader from "@Components/wrappers/StepsHeader/StepsHeader";

// Style
import style from "./Completed.module.css";
import templateStyle from "../../../onboardingEmployee/PersonalInformation/PersonalInformation.module.css";

// Components
import FormHeader from "@Components/ui/FormHeader/FormHeader";
import {
  CheckIcon,
  CredentialCardIcon,
  OperationSuccessIcon,
  TrophyIcon,
} from "@Models/icons";
//@ts-ignore
import { ButtonWithoutIcon, SlideNotification } from "@viuti/recursos";
import LoaderScreen from "@Components/wrappers/LoaderScreen/LoaderScreen";
import { useSearchParams } from "react-router-dom";
import ExpirationUrl from "@Pages/Expiration/Expiration";
import createEverything from "@Adapters/admin/createEverything.adapter";
import { navigateToHomeAdapter } from "@Adapters/admin/navigateToHome.adapter";
import { ToasterContext } from "@Hooks/useToasterContext";

const CompletedAdmin = () => {
  const toasterContext: any = useContext(ToasterContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isValidation, setIsValidation] = useState<boolean>(true);
  const [isExpired, setIsExpired] = useState<boolean>(false);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [queryParams] = useSearchParams();
  const [accountCreationResponse, setAccountCreationResponse] = useState(null);

  const signIn = async () => {
    setIsLoading(true);
    await navigateToHomeAdapter({
      ...accountCreationResponse,
    });
    setIsLoading(false);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      //complete the admin login
      const token = queryParams.get("token");
      localStorage.clear();
      localStorage.setItem("access_token", token);
      if (!token) {
        setIsValidation(false);
        setIsExpired(true);
        return;
      }
      const response: any = await createEverything({
        token: token,
        bitGoogle: 0,
      });

      const isSuccess = response?.isSuccess;
      if (isSuccess) {
        setIsExpired(false);
        setAccountCreationResponse(response.data);
        setIsValidation(false);
      } else {
        setIsExpired(true);
        setIsValidation(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isValidation) return <LoaderScreen visible={true} />;
  if (isExpired) return <ExpirationUrl />;
  return (
    <>
      <div className={templateStyle.viewContainer}>
        <StepsHeader
          currentStep={3}
          steps={[
            {
              icon: CredentialCardIcon,
              text: "Datos",
            },
            {
              icon: CheckIcon,
              text: "Validación",
            },
            {
              icon: TrophyIcon,
              text: "Completado",
            },
          ]}
        />
        <div className={templateStyle.viewContent}>
          {windowSize.width > 768 && (
            <FormHeader
              title="¡Completado!"
              subtitle="Has creado tu cuenta, explora nuestra plataforma y completa la información sobre tu negocio."
            />
          )}
          <form
            className={`${templateStyle.formContainer} ${style.formContainer}`}
          >
            {windowSize.width <= 768 && (
              <div className={style.headerContainer}>
                <h2 className={style.form__title}>¡Completado!</h2>
                <p className={style.form__subtitle}>
                  Has creado tu cuenta, explora nuestra plataforma y completa la
                  información sobre tu negocio.
                </p>
              </div>
            )}
            <img src={OperationSuccessIcon} alt="completado" />
            <p className={style.formTitle}>
              Configura tu perfil de empresa para disfrutar de todas las
              funcionalidades de Prikly.
            </p>
            <ButtonWithoutIcon
              width="100%"
              isPrimary={true}
              textBttn="Ir a Prikly"
              handleClick={() => signIn()}
              isLoading={isLoading}
              isDisabled={isLoading}
            />
          </form>
        </div>
      </div>
      <SlideNotification
        state={toasterContext.state}
        clearStatus={() => toasterContext.handleToasterClearStatus()}
      />
    </>
  );
};

export default CompletedAdmin;
