import { putRegisterService } from "@Services/put";
import { ROUTE_EMPLOYEES } from "@Models/routes";
import { IResponse } from "@Models/interfaces/api";

export const updateEmployeeRegisterAdapter = async (
  navigate,
  toasterContext
) => {
  // try {
  // const storedPassword = localStorage.getItem("password");
  // const storedDocType = localStorage.getItem("documentType");
  // const storedDocumentNumber = localStorage.getItem("documentNumber");
  // const storedFirstName = localStorage.getItem("firstName");
  // const storedLastName = localStorage.getItem("lastName");
  // const storedBirthDate = localStorage.getItem("birthDate");
  // const storedNickname = localStorage.getItem("nickname");
  // const storedSex = localStorage.getItem("selectedSex");
  // const storedPhoneNumber = localStorage.getItem("phoneNumber");
  // const storedphoneCode = localStorage.getItem("phoneCode");
  // const storedUuidOnboarding = localStorage.getItem("uuidOnboarding");
  // const payload = {
  //   req: {
  //     password: storedPassword,
  //     documentType: Number(storedDocType),
  //     document: Number(storedDocumentNumber),
  //     name: storedFirstName,
  //     lastName: storedLastName,
  //     dateOfBirth: storedBirthDate,
  //     alias: storedNickname,
  //     phone: String("+" + storedphoneCode + storedPhoneNumber),
  //     sex: storedSex,
  //     uuidOnboarding: storedUuidOnboarding,
  //   },
  // };

  // const response: IResponse = await putRegisterService(payload);

  // if (response.status !== 200) {
  //   toasterContext.handleToasterStateChange({
  //     message: response.message,
  //     status: response.status,
  //     loading: false,
  //   });
  //   return false;
  // }

  navigate(ROUTE_EMPLOYEES.SERVICES);
  // } catch (error) {
  // toasterContext.handleToasterStateChange({
  //   message: error.response.message,
  //   status: error.response.status,
  //   loading: false,
  // });
  // }
};
