import { STATUS_OK } from "@Models/httpsStatus";
import { IResponse } from "@Models/interfaces/api";
import { ROUTE_EMPLOYEES } from "@Models/routes";
import { checkTokenService } from "@Services/get";

export const checkUserTokenAdapter = async (location: any, navigate: any) => {
  const token =
    new URLSearchParams(location.search).get("token") ||
    localStorage.getItem("token_onboarding");

  const employeeId = new URLSearchParams(location.search).get("idEmpleado");
  const uuidOnboarding = new URLSearchParams(location.search).get("identifier");

  const response: IResponse = await checkTokenService(token, uuidOnboarding);

  if (response.status !== STATUS_OK) {
    return {
      status: response.status,
      data: response.data,
      message: response.message,
    };
  }

  if (ROUTE_EMPLOYEES.ROUTE_MAIN_VIEW.includes(location.pathname)) {
    const lastToken = localStorage.getItem("token_onboarding") || "";
    const currentToken =
      new URLSearchParams(location.search).get("token") || "";

    if (
      lastToken.length > 0 &&
      currentToken.length > 0 &&
      lastToken !== currentToken
    ) {
      localStorage.removeItem("isOnboardingCompleted");
    }
  }

  localStorage.setItem("token_onboarding", token);
  localStorage.setItem("idEmployee", employeeId);
  localStorage.setItem("uuidOnboarding", uuidOnboarding);

  return {
    status: response.status,
    data: response.data,
    message: response.message,
  };
};
