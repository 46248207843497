// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TMu131z_mfciEGra0vqY {
  position: relative;
  width: 100%;
  color: #484848;
  font-family: Mulish;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.241px;
  display: flex;
  flex-direction: column;

  width: 100%;
  max-width: 730px;
  p:first-child {
    margin-bottom: 8px; /* Añadido margen inferior al primer párrafo */
  }
}

.TMu131z_mfciEGra0vqY label {
  display: flex;
  color: #4d4d4d;
  font-family: Mulish;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  white-space: nowrap;
}

.a8NlHsvQBzDOvetM31Ht {
  max-width: 575px;
  max-height: fit-content;
  height: fit-content;

  @media screen and (max-width: 916px) {
    justify-content: space-between;
  }
}

.wO_YH39qtUKr7dqvD7hO {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 612px) {
    flex-direction: column;
    gap: 16px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/views/onboardingEmployee/PersonalInformationTwo/PersonalInformationTwo.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;EACX,cAAc;EACd,mBAAmB;EACnB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,uBAAuB;EACvB,aAAa;EACb,sBAAsB;;EAEtB,WAAW;EACX,gBAAgB;EAChB;IACE,kBAAkB,EAAE,8CAA8C;EACpE;AACF;;AAEA;EACE,aAAa;EACb,cAAc;EACd,mBAAmB;EACnB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,uBAAuB;EACvB,mBAAmB;;EAEnB;IACE,8BAA8B;EAChC;AACF;;AAEA;EACE,WAAW;EACX,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;;EAEnB;IACE,sBAAsB;IACtB,SAAS;EACX;AACF","sourcesContent":[".countrySelectContainer {\n  position: relative;\n  width: 100%;\n  color: #484848;\n  font-family: Mulish;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: normal;\n  letter-spacing: 0.241px;\n  display: flex;\n  flex-direction: column;\n\n  width: 100%;\n  max-width: 730px;\n  p:first-child {\n    margin-bottom: 8px; /* Añadido margen inferior al primer párrafo */\n  }\n}\n\n.countrySelectContainer label {\n  display: flex;\n  color: #4d4d4d;\n  font-family: Mulish;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 500;\n  white-space: nowrap;\n}\n\n.formContainer {\n  max-width: 575px;\n  max-height: fit-content;\n  height: fit-content;\n\n  @media screen and (max-width: 916px) {\n    justify-content: space-between;\n  }\n}\n\n.actionButtonsContainer {\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n\n  @media screen and (max-width: 612px) {\n    flex-direction: column;\n    gap: 16px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"countrySelectContainer": `TMu131z_mfciEGra0vqY`,
	"formContainer": `a8NlHsvQBzDOvetM31Ht`,
	"actionButtonsContainer": `wO_YH39qtUKr7dqvD7hO`
};
export default ___CSS_LOADER_EXPORT___;
