import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// Styles
import templateStyle from "../PersonalInformation/PersonalInformation.module.css";
import style from "./PersonalInformationTwo.module.css";

// Components
import StepsHeader from "@Components/wrappers/StepsHeader/StepsHeader";
import CustomSelect from "./CustomSelect";
//@ts-ignore
import { ButtonWithoutIcon } from "@viuti/recursos";
import MobileFormHeader from "@Components/ui/FormHeader/MobileFormHeader";
import FormHeader from "@Components/ui/FormHeader/FormHeader";

// Functions
import { handleInputChangePhone } from "./functions/handleInputChangePhone";
import { handleSubmit } from "./functions/handleSubmit";
import { ROUTE_EMPLOYEES } from "@Models/routes";
import { ToasterContext } from "@Hooks/useToasterContext";

const PersonalInformationTwo = () => {
  const navigate = useNavigate();
  const toasterContext: any = useContext(ToasterContext);

  const [isLoading, setIsLoading] = useState(false);

  const [phoneValues, setPhoneValues] = useState({
    phoneCode: Number(localStorage.getItem("phoneCode")) || 51,
    phoneNumber: localStorage.getItem("phoneNumber") || "",
  });
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [error, setError] = useState("");

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className={templateStyle.viewContainer}>
      <StepsHeader currentStep={1} responsive />

      <div className={templateStyle.viewContent}>
        {windowSize.width > 768 && (
          <FormHeader
            title="Datos personales"
            subtitle="Continúa completando los siguientes datos."
          />
        )}
        <form
          className={`${templateStyle.formContainer} ${style.formContainer}`}
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          {windowSize.width <= 768 && (
            <MobileFormHeader
              title="Datos personales"
              subtitle="Continúa completando los siguientes datos."
              onBack={() => navigate(ROUTE_EMPLOYEES.PERSONAL_INFORMATION)}
              onNext={null}
              isLoading={isLoading}
            />
          )}
          <div className={style.countrySelectContainer}>
            <p>Teléfono móvil</p>
            <CustomSelect
              phoneValues={{
                phoneCode: phoneValues.phoneCode,
                phoneNumber: phoneValues.phoneNumber,
              }}
              setPhoneValues={setPhoneValues}
              handleInputChange={(e) =>
                handleInputChangePhone(e, phoneValues, setPhoneValues)
              }
              error={error}
            />
          </div>

          <div className={style.actionButtonsContainer}>
            {windowSize.width > 768 && (
              <ButtonWithoutIcon
                isPrimary={false}
                textBttn={"Regresar"}
                handleClick={() =>
                  navigate(ROUTE_EMPLOYEES.PERSONAL_INFORMATION)
                }
                width={windowSize.width <= 612 ? "100%" : "auto"}
              />
            )}
            <ButtonWithoutIcon
              isPrimary
              textBttn={"Siguiente"}
              handleClick={() =>
                handleSubmit(
                  setError,
                  navigate,
                  phoneValues,
                  toasterContext,
                  setIsLoading
                )
              }
              width={windowSize.width <= 768 ? "100%" : "auto"}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default PersonalInformationTwo;
