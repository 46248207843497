import { getAllServicesService } from "@Services/get";
import { IResponse, IService } from "@Models/interfaces/api";

export const getAllServices = async () => {
  const response: IResponse = await getAllServicesService();

  let services: IService[] = [];

  if (!response.isSuccess) {
    return response;
  }

  services = response.data.map((service) => {
    return {
      idService: service.idService,
      name: service.serviceName,
      idLounge: service.idLocal,
    };
  });

  return {
    isSuccess: true,
    status: response.status,
    data: services || [],
    message: response.message,
  };
};
