// Styles
import style from "./FormHeader.module.css";

interface FormHeaderProps {
  title: string;
  subtitle: string;
}

const FormHeader = ({ title, subtitle }: FormHeaderProps) => {
  return (
    <div className={style.headerContainer}>
      <h2>{title}</h2>
      <p>{subtitle}</p>
    </div>
  );
};

export default FormHeader;
