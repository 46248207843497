// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.uXe9wEk2XirKyo4uqDAd {
  background-color: #f4f2fe;
  width: 100vw;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar-track {
    background-color: #fff;
    border-radius: 0;
  }
}

.Hg5UMTmoeSRxgZgWO7wL {
  width: 100vw;
  height: fit-content;
}

.iQZfk5R06_VOlzpTD8px {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1em;
  padding: 30px 20px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/views/onboardingAdmin/OnboardingAdmin.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,gBAAgB;EAChB,kBAAkB;;EAElB;IACE,sBAAsB;IACtB,gBAAgB;EAClB;AACF;;AAEA;EACE,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,QAAQ;EACR,kBAAkB;AACpB","sourcesContent":[".onboardingAdmin__mainContent {\n  background-color: #f4f2fe;\n  width: 100vw;\n  display: flex;\n  flex-direction: column;\n  overflow-y: auto;\n  overflow-x: hidden;\n\n  &::-webkit-scrollbar-track {\n    background-color: #fff;\n    border-radius: 0;\n  }\n}\n\n.onboardingAdmin__header {\n  width: 100vw;\n  height: fit-content;\n}\n\n.onboardingAdmin__section {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  gap: 1em;\n  padding: 30px 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"onboardingAdmin__mainContent": `uXe9wEk2XirKyo4uqDAd`,
	"onboardingAdmin__header": `Hg5UMTmoeSRxgZgWO7wL`,
	"onboardingAdmin__section": `iQZfk5R06_VOlzpTD8px`
};
export default ___CSS_LOADER_EXPORT___;
