import { updateEmployeeRegisterAdapter } from "@Adapters/updateEmployeeRegister.adapter";

export const handleSubmit = async (
  setError,
  navigate,
  numero,
  toasterContext,
  setIsLoading
) => {
  if (!numero.phoneNumber.length) {
    setError("El número de teléfono es obligatorio");
    return;
  }
  if (numero.phoneNumber.length > 0 && numero.phoneNumber.length !== 9) {
    setError("El número de teléfono debe tener 9 dígitos");
    return;
  }
  if (numero.phoneNumber.length > 9) {
    setError("El número de teléfono debe tener 9 dígitos");
    return;
  }

  setIsLoading(true);
  await updateEmployeeRegisterAdapter(navigate, toasterContext);
  setIsLoading(false);
};
