// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IRnAlTKMHauFVgUOnGov {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

.FW_CXaFY6tAOD7pvoJ_E {
  color: #333;
  font-size: 28px;
  margin-bottom: 10px;
}

.nN8VQkImXKHUaRG05Xs4 {
  color: #666;
  font-size: 16px;
  margin-bottom: 30px;
}

.ezdl1XqBsPsNJSZUAcAd {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 30px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.dKBbtDpvLG8PZF7Dji5A {
  font-size: 18px;
  color: #444;
  margin-bottom: 20px;
}

.e_N7xdkpVpYJ2MLXdTt9 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.CT_9SIeWl_8wQoe6oqZw {
  background-color: #6c5ce7;
  color: white;
  border: none;
  padding: 8px 14px;
  border-radius: 5px;
  font-size: 0.875rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.CT_9SIeWl_8wQoe6oqZw:hover:not(:disabled) {
  background-color: #5b4bc4;
}

.digfFPk3qGvchX51tkkm {
  opacity: 0.6;
  cursor: not-allowed;
}

.FiMQ7UMK8aFDhcqHYSl4 {
  font-size: 0.9rem;
  font-weight: bold;
  color: #6c5ce7;
}

.UsRKFCs5RRP5hZGg2yfg {
  font-size: 0.875rem;
  color: #888;
  margin-top: 10px;
}

.xnO9CsX_WvLA8Bd0LCuI {
  display: flex;
  gap: 12px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/views/onboardingAdmin/views/EmailConfirmation/EmailConfirmation.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,cAAc;EACd,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,aAAa;EACb,yCAAyC;AAC3C;;AAEA;EACE,eAAe;EACf,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,iBAAiB;EACjB,kBAAkB;EAClB,mBAAmB;EACnB,eAAe;EACf,sCAAsC;AACxC;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,mBAAmB;EACnB,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,SAAS;AACX","sourcesContent":[".container {\n  max-width: 600px;\n  margin: 0 auto;\n  padding: 20px;\n  text-align: center;\n}\n\n.title {\n  color: #333;\n  font-size: 28px;\n  margin-bottom: 10px;\n}\n\n.subtitle {\n  color: #666;\n  font-size: 16px;\n  margin-bottom: 30px;\n}\n\n.card {\n  background-color: #f8f9fa;\n  border-radius: 8px;\n  padding: 30px;\n  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);\n}\n\n.instruction {\n  font-size: 18px;\n  color: #444;\n  margin-bottom: 20px;\n}\n\n.resendContainer {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  gap: 15px;\n}\n\n.resendButton {\n  background-color: #6c5ce7;\n  color: white;\n  border: none;\n  padding: 8px 14px;\n  border-radius: 5px;\n  font-size: 0.875rem;\n  cursor: pointer;\n  transition: background-color 0.3s ease;\n}\n\n.resendButton:hover:not(:disabled) {\n  background-color: #5b4bc4;\n}\n\n.buttonInactive {\n  opacity: 0.6;\n  cursor: not-allowed;\n}\n\n.countdown {\n  font-size: 0.9rem;\n  font-weight: bold;\n  color: #6c5ce7;\n}\n\n.resendInfo {\n  font-size: 0.875rem;\n  color: #888;\n  margin-top: 10px;\n}\n\n.resendButtonContainer {\n  display: flex;\n  gap: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `IRnAlTKMHauFVgUOnGov`,
	"title": `FW_CXaFY6tAOD7pvoJ_E`,
	"subtitle": `nN8VQkImXKHUaRG05Xs4`,
	"card": `ezdl1XqBsPsNJSZUAcAd`,
	"instruction": `dKBbtDpvLG8PZF7Dji5A`,
	"resendContainer": `e_N7xdkpVpYJ2MLXdTt9`,
	"resendButton": `CT_9SIeWl_8wQoe6oqZw`,
	"buttonInactive": `digfFPk3qGvchX51tkkm`,
	"countdown": `FiMQ7UMK8aFDhcqHYSl4`,
	"resendInfo": `UsRKFCs5RRP5hZGg2yfg`,
	"resendButtonContainer": `xnO9CsX_WvLA8Bd0LCuI`
};
export default ___CSS_LOADER_EXPORT___;
