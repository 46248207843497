import { createAdminUser } from "@Adapters/registerNewAdminProfile.adater";
import { IFormValues } from "../OnboardingAdmin.page";
import resendEmailValidation from "@Adapters/admin/resendEmailValidation.adapter";
import { IToasterState } from "@Models/interfaces/toaster";

const useTheApiCalls = (
  formValues: IFormValues,
  setIsLoading: (value: boolean) => void,
  setCurrentView: (value: number) => void,
  handleToasterStateChange: (state: IToasterState) => void
) => {
  const continueWithTheOnboarding = async () => {
    //1. validation the formValues
    setIsLoading(true);
    if (
      formValues.email === "" ||
      formValues.password === "" ||
      formValues.personalName === "" ||
      formValues.personalSurname === "" ||
      formValues.mobilePhone === "" ||
      formValues.businessName === ""
    )
      return;

    //2. If the data is right, so we can call the API
    const response = await createAdminUser(
      formValues,
      handleToasterStateChange
    );
    setIsLoading(false);

    if (response.isSuccess) {
      setCurrentView(1);
    }
  };

  const sendEmailAgain = async () => {
    handleToasterStateChange({
      message: "Reenviando correo...",
      status: "pending",
      loading: true,
    });
    await resendEmailValidation(formValues.email, handleToasterStateChange);
  };

  return {
    sendEmailAgain,
    continueWithTheOnboarding,
  };
};

export default useTheApiCalls;
