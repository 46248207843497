// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s9N61t_LkXJlKyROugU4 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  user-select: none;
  margin-top: -60px;
  width: 100vw;
  height: 100vh;
}

.LMBqKWVi9DjwrelHF1Mw {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 1;
  user-select: none;
}

.LMBqKWVi9DjwrelHF1Mw h3 {
  width: 356px;
  height: 61px;
  color: #fff;
  text-align: center;
  font-family: Mulish;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  margin-top: 40px;
  margin-bottom: 60px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Expiration/Expiration.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,sBAAsB;EACtB,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,UAAU;EACV,iBAAiB;EACjB,iBAAiB;EACjB,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,sBAAsB;EACtB,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,UAAU;EACV,iBAAiB;AACnB;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,WAAW;EACX,kBAAkB;EAClB,mBAAmB;EACnB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,gBAAgB;EAChB,mBAAmB;AACrB","sourcesContent":[".containerForm {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n  width: 100%;\n  height: 100%;\n  position: absolute;\n  z-index: 1;\n  user-select: none;\n  margin-top: -60px;\n  width: 100vw;\n  height: 100vh;\n}\n\n.linkExpiro {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n  width: 100vw;\n  height: 100vh;\n  position: absolute;\n  z-index: 1;\n  user-select: none;\n}\n\n.linkExpiro h3 {\n  width: 356px;\n  height: 61px;\n  color: #fff;\n  text-align: center;\n  font-family: Mulish;\n  font-size: 24px;\n  font-style: normal;\n  font-weight: 800;\n  line-height: normal;\n  margin-top: 40px;\n  margin-bottom: 60px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerForm": `s9N61t_LkXJlKyROugU4`,
	"linkExpiro": `LMBqKWVi9DjwrelHF1Mw`
};
export default ___CSS_LOADER_EXPORT___;
