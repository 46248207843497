import OnboardingEmployee from "@Routes/OnboardingEmployee.routes";
import OnboardingAdmin from "@Routes/OnboardingAdmin.routes";
import { BrowserRouter } from "react-router-dom";
import "./App.css";

import { ToasterProvider } from "@Hooks/useToasterContext";

export default function App() {
  const location = window.location;
  const isAdminOnboarding = location.pathname.includes("/admin");

  return (
    <ToasterProvider>
      <BrowserRouter basename="/onboarding">
        {isAdminOnboarding ? <OnboardingAdmin /> : <OnboardingEmployee />}
      </BrowserRouter>
    </ToasterProvider>
  );
}
