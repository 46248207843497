// eslint-disable-file no-unused-vars
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// Styles
import templateStyle from "../PersonalInformation/PersonalInformation.module.css";
import style from "./Services.module.css";

import { postServiceEmploye } from "@Services/post";
import { getAllServices } from "@Adapters/getAllServices.adapter";

// Components
import {
  ButtonWithoutIcon,
  TextInput,
  //@ts-ignore
} from "@viuti/recursos";
import StepsHeader from "@Components/wrappers/StepsHeader/StepsHeader";
import FormHeader from "@Components/ui/FormHeader/FormHeader";

// Models
import { ROUTE_EMPLOYEES } from "@Models/routes";
import { LoadingPulseIcon, MagnifyingGlassIcon } from "@Models/icons";
import {
  IToasterContextProps,
  IToasterStore,
  ToasterContext,
} from "@Hooks/useToasterContext";

const Services = () => {
  const toasterContext = useContext(ToasterContext) as IToasterContextProps;
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedServices, setSelectedServices] = useState([]);
  const [servicesResponse, setServicesResponse] = useState({
    isSuccess: null,
    status: 0,
    data: [],
  });
  const [isLoading, setIsLoading] = useState(false);

  // Obtener el token y otros parámetros de la URL
  const searchParams = new URLSearchParams(window.location.search);
  const token = searchParams.get("token");
  const idEmpleado = searchParams.get("idEmpleado");
  const identifier = searchParams.get("identifier");

  useEffect(() => {
    const selectedServicesFromLocalStorage = JSON.parse(
      localStorage.getItem("selectedServicesOnboarding")
    );
    if (selectedServicesFromLocalStorage?.length) {
      setSelectedServices(selectedServicesFromLocalStorage);
    }
  }, []);

  useEffect(() => {
    getAllServices().then((response) => {
      setServicesResponse(response);
    });
  }, []);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const handleCheckboxChange = (idService) => {
    setSelectedServices((prev) =>
      prev.includes(idService)
        ? prev.filter((id) => id !== idService)
        : [...prev, idService]
    );
  };

  const handleSelectAll = () => {
    setSelectedServices(
      selectedServices.length === servicesResponse.data.length
        ? []
        : servicesResponse.data.map((service) => service.idService)
    );
  };

  const handleSubmit = async () => {
    navigate(
      `${ROUTE_EMPLOYEES.SCHENDULES}?token=${token}&idEmpleado=${idEmpleado}&identifier=${identifier}`
    );
  };

  const handleBack = () => {
    navigate(
      `${ROUTE_EMPLOYEES.PERSONAL_INFORMATION}?token=${token}&idEmpleado=${idEmpleado}&identifier=${identifier}`
    );
  };

  const filteredServices = servicesResponse.data.filter((service) =>
    service.name.toLowerCase().includes(searchTerm)
  );

  const allSelected =
    filteredServices.length > 0 &&
    filteredServices.every((service) =>
      selectedServices.includes(service.idService)
    );

  useEffect(() => {
    // Actualiza el localStorage cada vez que selectedServices cambie
    localStorage.setItem(
      "selectedServicesOnboarding",
      JSON.stringify(selectedServices)
    );
  }, [selectedServices]); // Dependencia en selectedServices

  return (
    <div className={templateStyle.viewContainer}>
      <StepsHeader currentStep={2} responsive />
      <div className={templateStyle.viewContent}>
        <FormHeader
          title="Servicios"
          subtitle="Selecciona los servicios en los que trabajas."
        />

        <form
          className={style.formContainer}
          onSubmit={(e) => e.preventDefault()}
        >
          <div className={style.servicesContainer}>
            <TextInput
              label={""}
              placeholder={"Buscar servicios"}
              leftIcon={MagnifyingGlassIcon}
              value={searchTerm}
              handleChange={handleSearch}
            />
            {servicesResponse.isSuccess !== null &&
            servicesResponse.data.length === 0 ? (
              <p className={style.noServicesMessage}>
                <span>
                  Lo sentimos, actualmente no hay servicios disponibles para
                  seleccionar.
                </span>
                Por favor, comuníquese con su administrador para obtener más
                información y asistencia.
              </p>
            ) : servicesResponse.status === 0 ? (
              <div className={style.loadingSpinnerContainer}>
                <img src={LoadingPulseIcon} alt="Cargando" />
              </div>
            ) : (
              <div className={style.checkboxGroup}>
                <label className={style.checkboxContainer}>
                  <input
                    type="checkbox"
                    checked={allSelected}
                    onChange={handleSelectAll}
                  />
                  <span className={style.checkboxLabel}>
                    Seleccionar todos los servicios
                  </span>
                </label>
                {filteredServices.map((service) => (
                  <label
                    key={service.idService}
                    className={style.checkboxContainer}
                  >
                    <input
                      type="checkbox"
                      checked={selectedServices.includes(service.idService)}
                      onChange={() => handleCheckboxChange(service.idService)}
                    />
                    <span className={style.checkboxLabel}>
                      {service.name.charAt(0).toUpperCase() +
                        service.name.slice(1).toLowerCase()}
                    </span>
                  </label>
                ))}
              </div>
            )}
          </div>
          <div className={style.actionButtonsContainer}>
            <ButtonWithoutIcon
              isPrimary={false}
              textBttn={"Regresar"}
              handleClick={handleBack} // Función para regresar
            />
            <ButtonWithoutIcon
              isPrimary
              textBttn="Continuar"
              isDisabled={isLoading}
              handleClick={handleSubmit}
              isLoading={isLoading}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default Services;
