import axios from "axios";
import { onboardingAdmin_GW } from "../const";
import { IResponse } from "@Models/interfaces/api";

interface ITrackEmailPayload {
  origin: number;
  hash: string;
}

const trackEmail_service = async (
  payload: ITrackEmailPayload
): Promise<IResponse> => {
  const localToken = localStorage.getItem("access_token");
  const url = `${onboardingAdmin_GW}/Onboarding/TackingEmailBranding`;

  try {
    const response = await axios.post(url, payload, {
      headers: {
        Authorization: `Bearer ${localToken}`,
      },
    });

    return {
      isSuccess: true,
      ...response.data,
    };
  } catch (error) {
    return {
      isSuccess: false,
      status: error.response?.status || 500,
      message:
        error.response?.data?.message ||
        "Ups! ha ocurrido un error, intenta de nuevo.",
      data: null,
    };
  }
};

export default trackEmail_service;
