import { useEffect, useState } from "react";
//@ts-ignore
import inherit_style from "../AccountForm/AccountForm.module.css";
import style from "./EmailConfirmation.module.css";

const EmailConfirmation = ({
  sendEmailAgain,
  setCurrentStep,
  currentEmail,
}) => {
  const [countdown, setCountdown] = useState(0);

  useEffect(() => {
    setCurrentStep(2);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (countdown > 0) {
      const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
      return () => clearTimeout(timer);
    }
  }, [countdown]);

  const handleResendEmail = () => {
    if (countdown === 0) {
      sendEmailAgain();
      setCountdown(60); // Iniciar el contador en 60 segundos (1 minuto)
    }
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  return (
    <>
      <h1 className={inherit_style.mainContent__title}>
        Verifica tu correo electrónico
      </h1>

      <p className={inherit_style.mainContent__subtitle}>
        Revisa tu bandeja de entrada en <b>{currentEmail}</b>, recuerda también
        revisar en spam
      </p>
      <article className={inherit_style.mainContent__emailCard__confirmation}>
        <p>Haz click en verificar dentro del correo enviado.</p>
        <div className={`${inherit_style.signIn} ${style.resendContainer}`}>
          ¿No recibiste nuestro correo?{" "}
          <button
            className={`${style.resendButton} ${
              countdown > 0 ? style.buttonInactive : ""
            }`}
            onClick={handleResendEmail}
            disabled={countdown > 0}
          >
            Reenviar correo
          </button>
        </div>
        {countdown > 0 && (
          <p className={style.resendInfo}>
            Podrás volver a reenviar el correo en <b>{formatTime(countdown)}</b>{" "}
            segundos.
          </p>
        )}
      </article>
    </>
  );
};

export default EmailConfirmation;
