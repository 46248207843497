import { baseUrl } from "./constGlobals";
import axios from "axios";

export const getTypeDocsService = async (tokenLocal) => {
  const url = `${baseUrl}/Documentos/TiposDeDocumento`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${tokenLocal}`,
      },
    });

    return response.data;
  } catch (error) {
    return {
      status: error.response.status,
      data: error.response.data,
      message: "No se pudo verificar el token",
    };
  }
};

export const getSexs = async (tokenLocal) => {
  const url = `${baseUrl}/TipoSexo/ListarTiposDeSexo`;
  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${tokenLocal}`,
    },
  });
  return response.data.data;
};

export const getAllServicesService = async () => {
  const tokenLocal =
    new URLSearchParams(location.search).get("token") ||
    localStorage.getItem("token_onboarding");
  const url = `${baseUrl}/Services/ListServices`;

  try {
    const response = await axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${tokenLocal}`,
        },
      })
      .then((res) => res.data);
    return {
      isSuccess: true,
      ...response,
    };
  } catch (error) {
    return {
      isSuccess: false,
      status: error.response.status,
      data: error.response.data,
      message: "No se pudo verificar el token",
    };
  }
};

export const checkTokenService = async (tokenLocal, uuidOnboarding: string) => {
  const url = `${baseUrl}/Team/CheckStatus`;

  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${tokenLocal}`,
      },
      params: {
        uuidOnboarding,
      },
    });

    return {
      isSuccess: true,
      status: response.data.status,
      data: response.data.data,
      message: "Se verificó el token correctamente",
    };
  } catch (error) {
    return {
      isSuccess: false,
      status: error.response.status,
      data: error.response.data,
      message: "No se pudo verificar el token",
    };
  }
};
